import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { authApi } from 'store/auth/authApi';

import { initialAuthState, initialLoginError, initialRegisterError } from './data';
import { deleteToken, handleLoginErrors, handleRegisterErrors, setToken } from './utils';
import { userApi } from '../user/userApi';
import { AccountData, Role } from '../user/types';

import type { RootState } from 'store/store';
import type { BackendError } from './utils';
import type { ModalType, AuthState } from './types';

const slice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    logout: state => {
      state.isAuthenticated = false;
      state.token = null;
      deleteToken();
    },
  },
  extraReducers: builder => {
    builder

    /* Login */
      .addMatcher(authApi.endpoints.login.matchPending, (state: AuthState) => {
        state.loginError = initialLoginError;
      })
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state: AuthState, action) => {
        const token = action.payload.access_token;
        state.token = token;
        setToken(token);
      })
      .addMatcher(authApi.endpoints.login.matchRejected, (state: AuthState, action) => {
        if (!action.payload) { return; }
        const data = action.payload.data as BackendError;
        const status = action.payload.status as number;
        state.loginError = handleLoginErrors(status, data);
      })
      .addMatcher(authApi.endpoints.registration.matchPending, state => {
        state.registerErrors = initialRegisterError;
      })
      .addMatcher(authApi.endpoints.registration.matchRejected, (state, action) => {
        if (!action.payload) { return; }
        const data = action.payload.data as BackendError;
        const status = action.payload.status as number;
        state.registerErrors = handleRegisterErrors(status, data);
      })
    /*  Authentication test */
      .addMatcher(userApi.endpoints.account.matchFulfilled, (state: AuthState, action: PayloadAction<AccountData>) => {
        const { role } = action.payload;
        if (role === 'admin' || role === 'owner' || role === 'manager' || role === 'partner') {
          state.isAuthenticated = true;
        } else {
          state.loginError.common = 'Недостаточно прав';
          deleteToken();
        }
      })
      .addMatcher(userApi.endpoints.account.matchRejected, (state: AuthState, action) => {
        if (action.payload && action.payload.status === 401) {
          state.token = null;
          deleteToken();
        }
      });
  },
});

export const {
  logout,
} = slice.actions;
export default slice.reducer;

export const selectLoginError = (state: RootState) => state.auth.loginError;
export const selectRegisterError = (state: RootState) => state.auth.registerErrors;
export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
