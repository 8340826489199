import { ChangeUserFormValues } from 'components/dashboard/components/EditUserForm/EditUserForm';
import { api } from 'store/api';
import { CreatedType } from 'types/appType';
import { StatsInfo } from 'types/statsApi';
import { Adapter } from 'utils/adapter';

import type { AccountData, AdminLinkResponse, GetWithdrawalsParams, UpdateAccountParams, Withdrawals } from './types';


export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    account: builder.query<AccountData, void>({
      query: () => 'v1/user/me',
      transformResponse: Adapter.accauntData,
      providesTags: ['Auth', 'User'],
    }),

    updateAccout: builder.mutation<ChangeUserFormValues, UpdateAccountParams>({
      query: (data:ChangeUserFormValues) => ({
        url: 'v1/user/update',
        method: 'PUT',
        body: { ...data }
      }),
      invalidatesTags: ['Auth', 'User', 'Stats']
    }),

    getWithdrawals: builder.query<Withdrawals, GetWithdrawalsParams>({
      query: (params) => ({
        url: 'v1/user/withdrawals',
        params: {
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
          'order_by': params.orderBy,
        }
      }),
      transformResponse: Adapter.withdrawalsToClient,
      providesTags: ['User'],
      keepUnusedDataFor: 1,
    }),

    createWithdrawal: builder.mutation({
      query: (amount: number) => ({
        url: 'v1/user/withdrawals',
        method: 'POST',
        params: {
          amount
        }
      }),
      invalidatesTags: ['User']
    }),

    getTotalStats: builder.query<StatsInfo, CreatedType>({
      query: (params: CreatedType) => ({
        url: `v1/${params.role}/stats`,
        params: {
          'partners_only': params.partnersOnly,
          'created_from': params.createdFrom ? params.createdFrom : undefined,
          'created_to': params.createdTo ? params.createdTo : undefined,
        }
      }),
      providesTags: ['Stats'],
      transformResponse: Adapter.statsInfoToClient,
    }),

    getLink: builder.query<AdminLinkResponse, string>({
      query: (role:string) => ({
        url: `v1/${role}/link`,
        method: 'GET'
      }),
    }),

    createRemoteAccoutn: builder.mutation({
      query: (remoteId: number) => ({
        url: 'v1/user/remote_account',
        method: 'POST',
        params: {
          'remote_id': remoteId,
        }
      }),
      invalidatesTags: ['User'],
    })


  }),
});

export const {
  useAccountQuery,
  useUpdateAccoutMutation,
  useGetWithdrawalsQuery,
  useCreateWithdrawalMutation,
  useCreateRemoteAccoutnMutation,
  useGetTotalStatsQuery,
  useGetLinkQuery
} = userApi;
