import { useEffect } from 'react';
import { notification } from 'antd';

type Params = {
  successMessage?: React.ReactNode;
  errorMessage?: React.ReactNode;
  isSuccess?: boolean;
  isError?: boolean;
  onSuccess?: () => void;
  onError?: () => void;
};

export const useNotification = (params: Params) => {
  const {
    isSuccess,
    isError,
    successMessage = 'Операция успешно совершена',
    errorMessage = 'Произошла ошибка',
    onSuccess,
    onError,
  } = params;

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: successMessage,
        placement: 'top',
      });
      onSuccess?.();
    }
    if (isError) {
      notification.error({
        message: errorMessage,
        placement: 'top',
      });
      onError?.();
    }
  }, [isSuccess, isError]);
};
