import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ConfigProvider as AntdConfigProvider, theme, ThemeConfig } from 'antd';

type Mode = 'light' | 'dark';

type ConfigProviderContext = {
  mode: Mode;
  setMode: (mode: Mode) => void;
};

const defaultTheme: ThemeConfig = {
  'token': {
    'colorPrimary': '#7b47f2',
    'colorInfo': '#7b47f2',
  },
  'algorithm': theme.defaultAlgorithm,
  components: {
    Layout: {
      headerBg: '#FFFFFF',
      siderBg: '#FFFFFF',
    }
  }
};

const purpleTheme: ThemeConfig = {
  'token': {
    'colorPrimary': '#7b47f2',
    'colorInfo': '#7b47f2',
    'colorBgBase': '#1a162a',
    'wireframe': false,
    'colorBorder': '#ffffff33',
    'colorBgMask': '#00000099',
    'colorSuccess': '#00bb13',
    'colorError': '#ff6523',
    'colorBorderSecondary': '#ffffff33',
    'colorBgContainer': '#212128B3',
    'colorBgElevated': '#403e48B3',
    'colorBgLayout': '#03011780'
  },
  'algorithm': theme.darkAlgorithm,
  components: {
    Layout: {
      headerBg: '#212128B3',
      siderBg: '#212128B3',
    },
    Menu: {
      colorBgContainer: 'transparent',
    },
    Modal: {
      colorBgElevated: '#403e48E6',
      headerBg: 'transparent'
    },
    DatePicker: {
      colorBgElevated: '#212128F7',
    },
    Dropdown: {
      colorBgElevated: '#403e48F7',
    },
    Select: {
      colorBgElevated: '#403e48F7',
    }
  }
};

export const ConfigProviderContext = createContext<
  ConfigProviderContext | undefined
>(undefined);

export const ConfigProvider: FC<PropsWithChildren> = ({ children }) => {
  const [mode, setMode] = useState<Mode>(localStorage.getItem('color-theme-0.1') as Mode ?? 'dark');

  useEffect(() => {
    localStorage.setItem('color-theme-0.1', mode);
  }, [mode]);

  useEffect(() => {
    if (!localStorage.getItem('color-theme-0.1')) {
      localStorage.setItem('color-theme-0.1', mode);
    }
  }, []);

  return (
    <ConfigProviderContext.Provider value={{ mode, setMode }}>
      <AntdConfigProvider
        theme={
          mode === 'light'
            ? defaultTheme
            : purpleTheme
        }
      >
        {children}
      </AntdConfigProvider>
    </ConfigProviderContext.Provider>
  );
};

export const useConfigProvider = () => {
  const context = useContext(ConfigProviderContext);

  if (context === undefined) {
    throw new Error(
      'useConfigProvider must be used within a ConfigProvider',
    );
  }

  return context;
};
