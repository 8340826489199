import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import user from 'store/user/userSlice';
import auth from 'store/auth/authSlice';
import { api } from 'store/api';
import { initialAuthState } from 'store/auth/data';
import { getToken } from 'store/auth/utils';

import { appData } from './app/appSlice';

const preloadedState = { auth: { ...initialAuthState, token: getToken() } };

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth,
    user,
    'APP_DATA': appData.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
  preloadedState,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();

setupListeners(store.dispatch);
