import { initialLoginError, initialRegisterError } from './data';

import type { LoginErrors, RegisterErrors } from './types';

export const setToken = (token: string) => {
  localStorage.setItem('token', token);
};

export const getToken = (): string | null => localStorage.getItem('token');

export const deleteToken = () => {
  localStorage.removeItem('token');
};

export type ForbiddenError = {
  detail: string,
};

export type LogicError = {
  detail: {
    loc: string[],
    msg: string,
    type: string,
  }[]
};

export type BackendError = ForbiddenError | LogicError;

const loginErrorsMessage: Record<string, string> = {
  'value is not a valid email address': 'Не верный формат email',
  'Password incorrect': 'Неверный  пароль',
  'Incorrect password': 'Неверный  пароль',
  'User not found': 'Пользователь не найден'
};

export const handleLoginErrors = (status: number, data: BackendError): LoginErrors => {
  const { detail } = data;
  const errors: LoginErrors = { ...initialLoginError };

  if (typeof detail === 'string') {
    if (detail === 'Password incorrect' || detail === 'Incorrect password') {
      errors.password = 'Неверный  пароль';
    } else if (detail === 'User not found') {
      errors.email = 'Пользователь не найден';
    } else {
      errors.common = loginErrorsMessage[detail] ?? detail;
    }
  }

  if (Array.isArray(detail)) {
    detail.forEach(err => {
      const location = err.loc?.[1];
      const message = err.msg;
      if (location === 'email') {
        errors.email = loginErrorsMessage[message] ?? message;
      } else if (location === 'password') {
        errors.password = loginErrorsMessage[message] ?? message;
      } else {
        errors.common = loginErrorsMessage[message] ?? message;
      }
    });
  }
  return errors;
};

const errorsTranslates: Record<string, string> = {
  'Invalid name': 'Неправильное имя пользователя',
  'value is not a valid email address': 'Не верный формат email',
  'User exists': 'Пользователь уже зарегистрирован',
};

export const handleRegisterErrors = (status: number, data: BackendError): RegisterErrors => {
  const { detail } = data;
  const errors: RegisterErrors = { ...initialRegisterError };

  if (typeof detail === 'string') {
    if (detail === 'User exists') {
      errors.email = 'Пользователь уже зарегистрирован';
    } else {
      errors.common = detail;
    }
  }

  if (Array.isArray(detail)) {
    detail.forEach(err => {
      const location = err.loc?.[1];
      const message = err.msg;
      if (!message) { return; }

      if ((Object.keys(errors)).includes(location)) {
        errors[location as keyof RegisterErrors] = errorsTranslates[message] ?? message;
        if (location === 'ref_code') {
          errors.ref_code = 'Проблемы с реферальным кодом';
        }
      } else {
        errors.common = errorsTranslates[message] ?? message;
      }
    });
  }

  return errors;
};
