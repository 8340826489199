import React, { useState } from 'react';
import { Typography } from 'antd';

import { useGetPartnerPromocodeStatsQuery } from 'store/partner/partnerApi';
import ShortStatsModal, { type FilterValues } from 'components/shared/ShortStatsModal/ShortStatsModal';

import type { GetPromocodesStatsParams, Promocode } from 'types/promocodeTypes';

const initParams = {
  pageSize: 10,
  page: 1,
  createdFrom: undefined,
  createdTo: undefined,
};

type Params = Omit<GetPromocodesStatsParams, 'promocodeId'>

type Props = {
  opened?: boolean;
  onClose?: () => void;
  promocode?: Promocode | null;
}

const PromocodeStatsModal = ({ opened, promocode, onClose }: Props) => {
  const [params, setParams] = useState<Params>(initParams);

  const { data, isFetching } = useGetPartnerPromocodeStatsQuery({
    ...params,
    promocodeId: promocode?.id ?? 0,
  }, { skip: !opened || !promocode?.id });

  const handlePageChange = (page: number, pageSize: number) =>
    setParams((prevParams) => ({ ...prevParams, page, pageSize }));

  const handleOnFilter = ({ createdFrom, createdTo }: FilterValues) => {
    setParams({
      ...initParams,
      createdFrom: createdFrom,
      createdTo: createdTo,
    });
  };

  const resetParams = () =>
    setParams(initParams);

  const handleClose = () => {
    onClose?.();
    resetParams();
  };

  return (
    <ShortStatsModal
      title="Статистика по промокоду"
      stats={data}
      opened={opened}
      loading={isFetching}
      onClose={handleClose}
      onFilter={handleOnFilter}
      onFilterReset={resetParams}
      onPageChange={handlePageChange}
      renderDescription={() => (
        <>
          <Typography.Paragraph>
            <b>Промокод:</b> <i>{promocode?.name}</i>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <b>Создан:</b> <i>{promocode?.created}</i>
          </Typography.Paragraph>
        </>
      )}
    />
  );
};

export default PromocodeStatsModal;
