import { Button } from 'antd';
import { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';

import { useCreatePartnerPromocodesMutation, useGetPartnerPromocodesQuery } from 'store/partner/partnerApi';
import { useNotification } from 'hooks/useNotification';

import PromocodesTable from './components/PromocodesTable';
import CreatePromocodeModal from './components/CreatePromocodeModal';
import PromocodeStatsModal from './components/PromocodeStatsModal';

import type {
  CreatePromocodesParams,
  GetPromocodes,
  Promocode
} from 'types/promocodeTypes';

const initParams: GetPromocodes = {
  pageSize: 10,
  page: 1,
  ascending: false,
};

type Modals = 'create-promocode' | 'promocode-stats';

const PromocodesPage = () => {
  const [activeModal, setActiveModal] = useState<Modals | null>(null);
  const [selectedPromocode, setSelectedPromocode] = useState<Promocode | null>(null);
  const [params, setParams] = useState<GetPromocodes>(initParams);
  const { data, isFetching } = useGetPartnerPromocodesQuery(params);
  const [createPromocode, {
    isLoading,
    isSuccess,
    isError,
    error
  }] = useCreatePartnerPromocodesMutation();

  useNotification({
    isSuccess,
    isError,
    onSuccess: () => closeModal(),
  });

  const closeModal = () => setActiveModal(null);

  const handleCreatePromocode = (values: CreatePromocodesParams) => {
    createPromocode(values);
  };

  const handlePageChange = (page: number, pageSize: number) =>
    setParams((prevParams) => ({ ...prevParams, page, pageSize }));

  const handleOpenLinkStats = (item: Promocode) => {
    setSelectedPromocode(item);
    setActiveModal('promocode-stats');
  };


  return (
    <div>
      <Button
        type="primary"
        onClick={() => setActiveModal('create-promocode')}
        style={{ marginBottom: '15px' }}
        icon={<PlusOutlined />}
      >
        Новый промокод
      </Button>
      <PromocodesTable
        items={data?.items}
        itemsCount={data?.count}
        loading={isFetching}
        onPageChange={handlePageChange}
        onPromocodeStats={handleOpenLinkStats}
      />
      <PromocodeStatsModal
        opened={activeModal === 'promocode-stats'}
        onClose={closeModal}
        promocode={selectedPromocode}
      />
      <CreatePromocodeModal
        opened={activeModal === 'create-promocode'}
        loading={isLoading}
        onClose={closeModal}
        onCreate={handleCreatePromocode}
        erorrs={[error as string]}
      />
    </div>
  );
};

export default PromocodesPage;
