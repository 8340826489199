import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import React from 'react';

import { useTypedSelector } from 'store/store';
import { selectUserRole } from 'store/user/userSlice';

type Props = {
  onClick: (key: string) => void;

}

const ActionsDropdown = ({ onClick }: Props) => {
  const role = useTypedSelector(selectUserRole);
  const handleDropdownClick: MenuItemType['onClick'] =
    (info) => onClick(info.key);

  let items: MenuItemType[] = [
    {
      key: 'details',
      label: 'Редактировать',
      onClick: handleDropdownClick,
    },
    {
      key: 'stats',
      label: 'Статистика',
      onClick: handleDropdownClick,
    }
  ];


  if (role !== 'admin') {
    items = items.filter((subItems) => subItems.key !== 'stats');
  }

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <Button icon={<MoreOutlined />} size='small' />
    </Dropdown>
  );
};

export default ActionsDropdown;
