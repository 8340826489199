import React from 'react';
import { Table } from 'antd';

import { PlayerStats } from 'types/statsApi';
import { roundNumber } from 'utils/common';

type Props = {
  defaultPageSize?: number;
  playerStats?: PlayerStats[];
  playerStatsCount?: number;
  loading?: boolean;
  onPageChange?: (page: number, pageSize: number) => void;
  pageSize?: number;
  currentPage?: number;
  paginationSize?: 'default' | 'small';
  paginationPosition?: ('topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight' | 'none')[];
}

const StatsUsersTable = (props: Props) => {
  const {
    defaultPageSize,
    playerStats,
    playerStatsCount,
    loading,
    onPageChange,
    pageSize,
    currentPage,
    paginationSize = 'small',
    paginationPosition = ['bottomCenter']
  } = props;

  const usersTable = playerStats?.map((item) => ({ ...item, key: item.playerId }));

  return (
    <Table<PlayerStats>
      dataSource={usersTable}
      loading={loading}
      size='small'
      scroll={{ x: '100%' }}
      style={{ backdropFilter: 'blur(20px)', }}
      pagination={{
        size: paginationSize,
        showSizeChanger: true,
        pageSizeOptions: [1, 5, 10, 20, 30, 50, 100],
        defaultPageSize: defaultPageSize,
        position: paginationPosition,
        total: playerStatsCount,
        current: currentPage,
        pageSize: pageSize,
        onChange: onPageChange,
      }}
    >
      <Table.Column<PlayerStats>
        title="id"
        key="playerId"
        dataIndex="playerId"
      />
      <Table.Column<PlayerStats>
        title="Имя"
        key="playerName"
        dataIndex="playerName"
      />
      <Table.Column<PlayerStats>
        title="Депозиты"
        key="deposits"
        render={(_, { adminDeposits, deposits }) => (
          roundNumber(deposits + adminDeposits)
        )}
      />
      <Table.Column<PlayerStats>
        title="Прибыль ставки"
        key="betsIncome"
        dataIndex="betsIncome"
        render={roundNumber}
      />
      <Table.Column<PlayerStats>
        title="Прибыль Казино"
        key="casinoIncome"
        render={(_, { inbetIncome, betgamesIncome, vegasIncome }) => (
          roundNumber(inbetIncome + betgamesIncome + vegasIncome)
        )}
      />
      <Table.Column<PlayerStats>
        title="Доход компании"
        key="income"
        dataIndex="income"
        render={roundNumber}
      />
    </Table>
  );
};

export default StatsUsersTable;
