import { api } from 'store/api';
import { CreatedType } from 'types/appType';
import { StatsInfo, StatsInfoResponse } from 'types/statsApi';
import { Adapter } from 'utils/adapter';

const statsApi = api.injectEndpoints({
  endpoints: (builder) => ({

    linkStats: builder.query<StatsInfo, { id:string } & CreatedType>({
      query: (params) => ({
        url: `v1/${params.role}/stats/link/${params.id}`,
        params: {
          'created_from': params.createdFrom ? params.createdFrom : undefined,
          'created_to': params.createdTo ? params.createdTo : undefined
        }
      }),
      providesTags: ['Stats'],
      transformResponse: Adapter.statsInfoToClient,
    }),

    promocodeStats: builder.query<StatsInfo, { id:string } & CreatedType>({
      query: (params) => ({
        url: `v1/${params.role}/stats/promocode/${params.id}`,
        params: {
          'created_from': params.createdFrom ? params.createdFrom : undefined,
          'created_to': params.createdTo ? params.createdTo : undefined
        }
      }),
      providesTags: ['Stats'],
      transformResponse: Adapter.statsInfoToClient,
    }),

    projectStats: builder.query<StatsInfo, { id:number } & CreatedType>({
      query: (data) => ({
        url: `v1/${data.role}/stats/project/${data.id}`,
        params: {
          'created_from': data.createdFrom ? data.createdFrom : undefined,
          'created_to': data.createdTo ? data.createdTo : undefined
        }
      }),
      providesTags: ['Stats'],
      transformResponse: Adapter.statsInfoToClient,
    }),

    // <-- Общая статистика -->
    getTotalStatsAdminNew: builder.query<StatsInfo, void>({
      query: () => ({
        url: 'v1/admin/stats',
        params: {
          'partners_only': true,
          'created_from': '2023-07-31T21:00:00.000Z',
          'created_to': '2023-08-09T20:59:59.000Z'
        }
      }),
      transformResponse: Adapter.statsInfoToClient,
      providesTags: ['Stats'],
    }),
    getTotalStatsOwnerNew: builder.query<StatsInfo, void>({
      query: () => ({
        url: 'v1/owner/stats',
        params: {
          'partners_only': true,
          'created_from': '2023-07-31T21:00:00.000Z',
          'created_to': '2023-08-09T20:59:59.000Z'
        }
      }),
      transformResponse: Adapter.statsInfoToClient,
      providesTags: ['Stats'],
    }),
    getTotalStatsManagerNew: builder.query<StatsInfo, void>({
      query: () => ({
        url: 'v1/manager/stats',
        params: {
          'created_from': '2023-07-31T21:00:00.000Z',
          'created_to': '2023-08-09T20:59:59.000Z',
          'page_size': 30,
          'page': 1,
          'ascending': false,
        }
      }),
      transformResponse: Adapter.statsInfoToClient,
      providesTags: ['Stats'],
    }),
    getTotalStatsPartnerNew: builder.query<StatsInfo, void>({
      query: () => ({
        url: 'v1/partner/stats',
        params: {
          'created_from': '2023-07-31T21:00:00.000Z',
          'created_to': '2023-08-09T20:59:59.000Z',
          'page_size': 30,
          'page': 1,
          'ascending': false,
        }
      }),
      transformResponse: Adapter.statsInfoToClient,
      providesTags: ['Stats'],
    }),
    // <-- Партнёрская статитика -->
    getPartnerStatsAdminNew: builder.query<StatsInfo, { partnerId: number }>({
      query: (params) => ({
        url: `v1/admin/stats/partner/${params.partnerId}`,
        params: {
          'created_from': '2023-07-31T21:00:00.000Z',
          'created_to': '2023-08-09T20:59:59.000Z',
          'page_size': 30,
          'page': 1,
          'ascending': false,
        }
      }),
      transformResponse: Adapter.statsInfoToClient,
      providesTags: ['Stats'],
    }),
    getPartnerStatsOwnerNew: builder.query<StatsInfo, { partnerId: number }>({
      query: (params) => ({
        url: `v1/owner/stats/partner/${params.partnerId}`,
        params: {
          'created_from': '2023-07-31T21:00:00.000Z',
          'created_to': '2023-08-09T20:59:59.000Z',
          'page_size': 30,
          'page': 1,
          'ascending': false,
        }
      }),
      transformResponse: Adapter.statsInfoToClient,
      providesTags: ['Stats'],
    }),
    getPartnerStatsManagerNew: builder.query<StatsInfo, { partnerId: number }>({
      query: (params) => ({
        url: `v1/manager/stats/partner/${params.partnerId}`,
        params: {
          'created_from': '2023-07-31T21:00:00.000Z',
          'created_to': '2023-08-09T20:59:59.000Z',
          'page_size': 30,
          'page': 1,
          'ascending': false,
        }
      }),
      transformResponse: Adapter.statsInfoToClient,
      providesTags: ['Stats'],
    }),

    // <-- Проектная статистика -->
    getProjectStatsAdminNew: builder.query<StatsInfo, { projectId: number }>({
      query: (params) => ({
        url: `v1/admin/stats/project/${params.projectId}`,
        params: {
          'created_from': '2023-07-31T21:00:00.000Z',
          'created_to': '2023-08-09T20:59:59.000Z',
          'page_size': 30,
          'page': 1,
          'ascending': false,
        }
      }),
      transformResponse: Adapter.statsInfoToClient,
      providesTags: ['Stats'],
    }),
    getProjectStatsOwnerNew: builder.query<StatsInfo, { projectId: number }>({
      query: (params) => ({
        url: `v1/owner/stats/project/${params.projectId}`,
        params: {
          'created_from': '2023-07-31T21:00:00.000Z',
          'created_to': '2023-08-09T20:59:59.000Z',
          'page_size': 30,
          'page': 1,
          'ascending': false,
        }
      }),
      transformResponse: Adapter.statsInfoToClient,
      providesTags: ['Stats'],
    }),
    getProjectStatsManagerNew: builder.query<StatsInfo, { projectId: number }>({
      query: (params) => ({
        url: `v1/manager/stats/project/${params.projectId}`,
        params: {
          'created_from': '2023-07-31T21:00:00.000Z',
          'created_to': '2023-08-09T20:59:59.000Z',
          'page_size': 30,
          'page': 1,
          'ascending': false,
        }
      }),
      transformResponse: Adapter.statsInfoToClient,
      providesTags: ['Stats'],
    }),
    getProjectStatsPartnerNew: builder.query<StatsInfo, { projectId: number }>({
      query: (params) => ({
        url: `v1/partner/stats/project/${params.projectId}`,
        params: {
          'created_from': '2023-07-31T21:00:00.000Z',
          'created_to': '2023-08-09T20:59:59.000Z',
          'page_size': 30,
          'page': 1,
          'ascending': false,
        }
      }),
      transformResponse: Adapter.statsInfoToClient,
      providesTags: ['Stats'],
    }),

    // <-- Реферальная статистика -->
    getLinkStatsAdminNew: builder.query<StatsInfo, { refCode: number }>({
      query: (params) => ({
        url: `v1/admin/stats/link/${params.refCode}`,
        params: {
          'created_from': '2023-07-31T21:00:00.000Z',
          'created_to': '2023-08-09T20:59:59.000Z',
          'page_size': 30,
          'page': 1,
          'ascending': false,
        }
      }),
      transformResponse: Adapter.statsInfoToClient,
      providesTags: ['Stats'],
    }),
    getLinkStatsOwnerNew: builder.query<StatsInfo, { refCode: number }>({
      query: (params) => ({
        url: `v1/owner/stats/link/${params.refCode}`,
        params: {
          'created_from': '2023-07-31T21:00:00.000Z',
          'created_to': '2023-08-09T20:59:59.000Z',
          'page_size': 30,
          'page': 1,
          'ascending': false,
        }
      }),
      transformResponse: Adapter.statsInfoToClient,
      providesTags: ['Stats'],
    }),
    getLinkStatsManagerNew: builder.query<StatsInfo, { refCode: number }>({
      query: (params) => ({
        url: `v1/manager/stats/link/${params.refCode}`,
        params: {
          'created_from': '2023-07-31T21:00:00.000Z',
          'created_to': '2023-08-09T20:59:59.000Z',
          'page_size': 30,
          'page': 1,
          'ascending': false,
        }
      }),
      transformResponse: Adapter.statsInfoToClient,
      providesTags: ['Stats'],
    }),
    getLinkStatsPartnerNew: builder.query<StatsInfo, { refCode: number }>({
      query: (params) => ({
        url: `v1/partner/stats/link/${params.refCode}`,
        params: {
          'created_from': '2023-07-31T21:00:00.000Z',
          'created_to': '2023-08-09T20:59:59.000Z',
          'page_size': 30,
          'page': 1,
          'ascending': false,
        }
      }),
      transformResponse: Adapter.statsInfoToClient,
      providesTags: ['Stats'],
    }),

    // <-- Клиентская статистика -->
    getClientStatsAdmin: builder.query<StatsInfo, { clientId: number }>({
      query: (params) => ({
        url: `v1/admin/stats/client/${params.clientId}`,
        params: {
          'partners_only': true,
          'created_from': '2023-07-31T21:00:00.000Z',
          'created_to': '2023-08-09T20:59:59.000Z',
        }
      }),
      transformResponse: Adapter.statsInfoToClient,
      providesTags: ['Stats'],
    })
  })
});

export const {
  useLinkStatsQuery,
  usePromocodeStatsQuery,
  useProjectStatsQuery
} = statsApi;
