import React, { useState } from 'react';

import { useGetPartnerTotalStatsQuery } from 'store/partner/partnerApi';
import StatsUsersTable from 'components/shared/StatsUsersTable/StatsUsersTable';

import UsersFilter, { FilterValues } from './components/UsersFilter';

import type { GetPartnerTotalStats } from 'store/partner/types';
const defaultPageSize = 10;

const initParams = {
  ascending: false,
  pageSize: defaultPageSize,
  page: 1,
};

const UsersPage = () => {
  const [params, setParams] = useState<GetPartnerTotalStats>(initParams);
  const { data: statsData, isFetching } =
    useGetPartnerTotalStatsQuery(params);

  const handlePageChange = (page: number, pageSize: number) =>
    setParams((prevParams) => ({ ...prevParams, page, pageSize }));

  const handleFilterChange = (filter: FilterValues) =>
    setParams((prev) => ({ ...prev, ...filter }));

  return (
    <div>
      <UsersFilter onFilter={handleFilterChange} />
      <StatsUsersTable
        playerStats={statsData?.playerStats}
        playerStatsCount={statsData?.playerStatsCount}
        defaultPageSize={defaultPageSize}
        loading={isFetching}
        currentPage={params.page}
        pageSize={params.pageSize}
        onPageChange={handlePageChange}
        paginationSize='default'
        paginationPosition={['bottomRight']}
      />
    </div>
  );
};

export default UsersPage;
