import React, { useState } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { defaultPageSize } from 'utils/constants';
import { useCreatePartnerLinkMutation, useGetPartnerLinksQuery } from 'store/partner/partnerApi';
import { useNotification } from 'hooks/useNotification';
import { PartnerLink, GetPartnerLinks } from 'store/partner/types';

import AddLinksModal from './components/AddLinksModal';
import LinksTable from './components/LinksTable';
import LinkStatsModal from './components/LinkStatsModal';

const initParams = {
  pageSize: defaultPageSize,
  page: 1
};

type Modals = 'create-link' | 'link-stats';

const LinksPage = () => {
  const [activeModal, setActiveModal] = useState<Modals | null>(null);
  const [selectedLink, setSelectedLink] = useState<PartnerLink | null>(null);
  const [params, setParams] = useState<GetPartnerLinks>(initParams);
  const { data, isFetching } = useGetPartnerLinksQuery(params);

  const [createLink, {
    isSuccess: isCreateLinkSuccess,
    isError: isCreateLinkError,
    isLoading: isCreateLinkLoading,
    data: link
  }] = useCreatePartnerLinkMutation();

  useNotification({
    isSuccess: isCreateLinkSuccess,
    isError: isCreateLinkError,
    successMessage: `Ваша ссылка: ${link ?? ''}`,
    onSuccess: () => closeModal(),
  });

  const closeModal = () => {
    setActiveModal(null);
    setSelectedLink(null);
  };

  const handlePageChange = (page: number, pageSize: number) =>
    setParams((prevParams) => ({ ...prevParams, page, pageSize }));

  const handleCreateLink = (projectId: number) => {
    createLink({ projectId });
  };

  const handleOpenLinkStats = (linkItem: PartnerLink) => {
    setSelectedLink(linkItem);
    setActiveModal('link-stats');
  };

  return (
    <div>
      <Button
        type="primary"
        style={{ marginBottom: '15px' }}
        onClick={() => setActiveModal('create-link')}
        icon={<PlusOutlined />}
      >
        Новая ссылка
      </Button>
      <LinksTable
        items={data?.items}
        loading={isFetching}
        defaultPageSize={defaultPageSize}
        itemsCount={data?.count ?? 0}
        onPageChange={handlePageChange}
        onLinkStats={handleOpenLinkStats}
      />
      <LinkStatsModal
        opened={activeModal === 'link-stats'}
        onClose={closeModal}
        linkItem={selectedLink}
      />
      <AddLinksModal
        opened={activeModal === 'create-link'}
        onClose={closeModal}
        onCreate={handleCreateLink}
        loading={isCreateLinkLoading}
      />
    </div>
  );
};

export default LinksPage;
