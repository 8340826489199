import React from 'react';
import { Spin, Typography } from 'antd';

import { useGetLinkQuery } from 'store/user/userApi';
import { useTypedSelector } from 'store/store';
import { selectUserRole } from 'store/user/userSlice';

import TotalStats from '../TotalStats/TotalStats';

import './MainPage.scss';

const MainPage = () => {
  const role = useTypedSelector(selectUserRole);
  const { data, isLoading: isLoadingLink } = useGetLinkQuery(role || 'admin');

  if (isLoadingLink) {
    return <div><Spin /></div>;
  }

  return (
    <>
      <Typography.Title level={4}>
        Ваша реферальная ссылка:
      </Typography.Title>
      <Typography.Text copyable >
        {data?.data.link}
      </Typography.Text>
      <TotalStats />
    </>
  );
};

export default MainPage;
