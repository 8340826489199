import React, { useState } from 'react';
import { Typography } from 'antd';

import { GetPartnerProjectStats, PartnerProject } from 'store/partner/types';
import { useGetPartnerProjectStatsQuery } from 'store/partner/partnerApi';
import ShortStatsModal, { type FilterValues } from 'components/shared/ShortStatsModal/ShortStatsModal';

const initParams = {
  pageSize: 10,
  page: 1,
  createdFrom: undefined,
  createdTo: undefined,
};

type Params = Omit<GetPartnerProjectStats, 'projectId'>

type Props = {
  opened?: boolean;
  onClose?: () => void;
  project?: PartnerProject | null;
}

const ProjectStatsModal = ({ opened, project, onClose }: Props) => {
  const [params, setParams] = useState<Params>(initParams);

  const { data, isFetching } = useGetPartnerProjectStatsQuery({
    ...params,
    projectId: project?.id ?? 0,
  }, { skip: !opened || !project?.id });

  const handlePageChange = (page: number, pageSize: number) =>
    setParams((prevParams) => ({ ...prevParams, page, pageSize }));

  const handleOnFilter = ({ createdFrom, createdTo }: FilterValues) => {
    setParams({
      ...initParams,
      createdFrom: createdFrom,
      createdTo: createdTo,
    });
  };

  const resetParams = () =>
    setParams(initParams);

  const handleClose = () => {
    onClose?.();
    resetParams();
  };

  return (
    <ShortStatsModal
      title='Статистика по проекту'
      stats={data}
      opened={opened}
      loading={isFetching}
      onClose={handleClose}
      onFilter={handleOnFilter}
      onFilterReset={resetParams}
      onPageChange={handlePageChange}
      renderDescription={() => (
        <>
          <Typography.Paragraph>
            <b>Название:</b> <i>{project?.name}</i>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <b>Создан:</b> <i>{project?.created}</i>
          </Typography.Paragraph>
        </>
      )}
    />
  );
};

export default ProjectStatsModal;
