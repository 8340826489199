import React from 'react';

import { useGetUserStatQuery } from 'store/users/usersApi';
import { useTypedSelector } from 'store/store';
import { selectUserRole } from 'store/user/userSlice';
import StatsTableTotal from 'components/shared/StatsTableTotal/StatsTableTotal';

type Props = {
  id: number;
  transacionDates?: {
    transactionFrom?: string;
    transactionTo?: string;
  };
};

const UserStatsList = ({ id, transacionDates }: Props) => {
  const role = useTypedSelector(selectUserRole);
  const { data, isFetching } = useGetUserStatQuery({
    id,
    role: role || 'admin',
    createdFrom: transacionDates?.transactionFrom,
    createdTo: transacionDates?.transactionTo,
  });

  return (
    <StatsTableTotal
      data={data}
      loading={isFetching}
    />
  );
};

export default UserStatsList;
