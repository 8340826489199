import { Button, Form, Input, Select, Modal, Spin } from 'antd';
import React, { useEffect } from 'react';

import { useGetOwnerPromocodeScriptsQuery } from 'store/clients/clientsApi';

import type { CreatePromocodesParams } from 'types/promocodeTypes';

type FormValues = CreatePromocodesParams;

type Props = {
  opened?: boolean;
  loading?: boolean;
  onClose?: () => void;
  onCreate?: (values: CreatePromocodesParams) => void;
  erorrs?: string[];
}

const CreatePromocodeModal = (props: Props) => {
  const { opened, loading, onClose, onCreate, erorrs } = props;
  const [form] = Form.useForm<FormValues>();

  const { data: partnerPromoScripts } = useGetOwnerPromocodeScriptsQuery();

  useEffect(() => {
    if (opened) {
      form.setFields([{
        name: 'name',
        errors: erorrs,
      }]);
    }
  }, [erorrs]);

  useEffect(() => {
    form.resetFields();
  }, [opened]);

  const handleClose = () => {
    onClose?.();
    form.resetFields();
  };

  if (!partnerPromoScripts) {
    return <Spin />;
  }

  return (
    <Modal
      title="Создать промокод"
      open={opened}
      destroyOnClose
      onCancel={handleClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Отменить
        </Button>
      ]}
    >
      <Form<FormValues>
        form={form}
        layout="vertical"
        onFinish={(formData: FormValues) => onCreate?.(formData)}
        disabled={loading}
      >
        <Form.Item
          name="name"
          rules={[{ required: true, message: 'Введите промокод' }]}
        >
          <Input placeholder='Введите промокод' maxLength={16} minLength={4} />
        </Form.Item>

        {partnerPromoScripts.length > 0 && (
          <Form.Item
            label="Выберите скрипт, к которому будет привязан промокод"
            name="scriptId"
          >
            <Select>
              {partnerPromoScripts.map((script) => (
                <Select.Option key={script.id} value={script.id}>
                  {script.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Button key="submit" type="primary" htmlType="submit" loading={loading}>
          Создать
        </Button>
      </Form>
    </Modal>
  );
};

export default CreatePromocodeModal;
