import { Button, Form, Input } from 'antd';
import React from 'react';

import { useNotification } from 'hooks/useNotification';
import { useUpdateClientMutation } from 'store/clients/clientsApi';
import { ClientsItem, newClientsItem } from 'store/clients/types';
import { useTypedSelector } from 'store/store';
import { selectUserRole } from 'store/user/userSlice';

type Props = {
  item: ClientsItem | undefined;
  closeModal: () => void;
};

type FormValue = {
  defRevshareMul: number;
  domain: string;
  ownerId?: number;
  id: number | undefined;
  secret: string | undefined;
};

const ClientsDetails = ({ item, closeModal }: Props) => {
  const role = useTypedSelector(selectUserRole);
  const [form] = Form.useForm();
  const [update, { isSuccess, isError }] = useUpdateClientMutation();

  const onFinish = async (value: FormValue) => {
    const data: newClientsItem = {
      id: item?.id,
      role: role || 'admin',
      body: {
        domain: value?.domain,
        defRevshareMul: Number(value?.defRevshareMul),
      }
    };

    if (role === 'admin') {
      data.body.ownerId = Number(value.ownerId);
    }

    await update(data);
    closeModal();
  };

  useNotification({
    isSuccess,
    isError
  });

  return (
    <Form
      form={form}
      initialValues={item}
      onFinish={onFinish}
    >
      {role === 'admin' && (
        <Form.Item label="owner_id" name="ownerId">
          <Input type="number" />
        </Form.Item>
      )}
      <Form.Item label="domain" name="domain">
        <Input />
      </Form.Item>
      <Form.Item label="revshare" name="defRevshareMul">
        <Input />
      </Form.Item>
      <Form.Item label="secret" name="secret">
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">Сохранить</Button>
      </Form.Item>
    </Form>
  );
};

export default ClientsDetails;
