import { api } from 'store/api';
import { StatsInfo } from 'types/statsApi';
import { Adapter } from 'utils/adapter';

import type { BackendError } from 'types/errorTypes';
import type {
  GetPromocodes,
  Promocodes,
  PromocodeScript,
  GetPromocodesStatsParams,
  CreatePromocodesParams,
} from 'types/promocodeTypes';
import type {
  CreateLinkResponse,
  GetPartnerProjects,
  GetPartnerTotalStats,
  PartnerLinks,
  GetPartnerLinks,
  GetPartnerLiskStats,
  GetPartnerProjectStats,
  PartnerProjects,
  CreatePartnerLink,
} from './types';

const partnerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPartnerTotalStats: builder.query<StatsInfo, GetPartnerTotalStats>({
      query: (params) => ({
        url: 'v1/partner/stats',
        params: {
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
          'order_by': params.orderBy,
        }
      }),
      transformResponse: Adapter.statsInfoToClient,
    }),

    getPartnerProjects: builder.query<PartnerProjects, GetPartnerProjects>({
      query: (params) => ({
        url: 'v1/partner/projects',
        params: {
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
          'order_by': params.orderBy,
        }
      }),
      providesTags: ['Projects']
    }),

    getPartnerProjectStats: builder.query<StatsInfo, GetPartnerProjectStats>({
      query: ({ projectId, ...params }) => ({
        url: `v1/partner/stats/project/${projectId}`,
        params: {
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
          'order_by': params.orderBy,
        }
      }),
      transformResponse: Adapter.statsInfoToClient,
    }),

    createPartnerProject: builder.mutation<void, string>({
      query: (name) => ({
        url: 'v1/partner/projects',
        method: 'POST',
        params: {
          name
        }
      }),
      invalidatesTags: ['Projects']
    }),

    getPartnerLinks: builder.query<PartnerLinks, GetPartnerLinks>({
      query: (params) => ({
        url: 'v1/partner/links',
        params: {
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
          'order_by': params.orderBy,
        }
      }),
      providesTags: ['Links'],
      transformResponse: Adapter.partnerLinks,
    }),

    getPartnerLinkStats: builder.query<StatsInfo, GetPartnerLiskStats>({
      query: ({ refCode, ...params }) => ({
        url: `v1/partner/stats/link/${refCode}`,
        params: {
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
          'order_by': params.orderBy,
        }
      }),
      transformResponse: Adapter.statsInfoToClient,
    }),

    createPartnerLink: builder.mutation<string, CreatePartnerLink>({
      query: ({ projectId, title }) => ({
        url: 'v1/partner/links',
        method: 'POST',
        params: {
          'project_id': projectId,
          title,
        }
      }),
      transformResponse: (res: CreateLinkResponse) => res.data.link,
      invalidatesTags: ['Links']
    }),

    getPartnerPromocodes: builder.query<Promocodes, GetPromocodes>({
      query: (params) => ({
        url: 'v1/partner/promocodes',
        params: {
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
          'order_by': params.orderBy,
        }
      }),
      providesTags: ['Promocodes'],
    }),

    getPartnerPromocodeScripts: builder.query<PromocodeScript[], void>({
      query: () => ({
        url: 'v1/partner/promocode/scripts',
      }),
    }),

    createPartnerPromocodes: builder.mutation<void, CreatePromocodesParams>({
      query: (params) => ({
        url: 'v1/partner/promocodes',
        method: 'POST',
        params: {
          'name': params.name,
          'script_id': params.scriptId,
        }
      }),
      transformErrorResponse: (
        baseQueryReturnValue: {
          status: number;
          data?: BackendError;
        }
      ): string => {
        if (!baseQueryReturnValue.data || !baseQueryReturnValue.data.detail) {
          return 'Ошибка при создании промокода';
        }
        const { detail } = baseQueryReturnValue.data;
        if (typeof detail === 'string') {
          switch (detail) {
            case 'Promocode already exists':
              return 'Такой промокод уже существует';
            default:
              return detail;
          }
        }
        return 'Ошибка при создании промокода';
      },
      invalidatesTags: ['Promocodes'],
    }),

    getPartnerPromocodeStats: builder.query<StatsInfo, GetPromocodesStatsParams>({
      query: (params) => ({
        url: `v1/partner/stats/promocode/${params.promocodeId}`,
        params: {
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
          'order_by': params?.orderBy,
        }
      }),
      transformResponse: Adapter.statsInfoToClient,
    }),

  }),
});

export const {
  useGetPartnerTotalStatsQuery,
  useGetPartnerProjectsQuery,
  useGetPartnerProjectStatsQuery,
  useCreatePartnerProjectMutation,
  useGetPartnerLinksQuery,
  useGetPartnerLinkStatsQuery,
  useCreatePartnerLinkMutation,
  useGetPartnerPromocodesQuery,
  useCreatePartnerPromocodesMutation,
  useGetPartnerPromocodeScriptsQuery,
  useGetPartnerPromocodeStatsQuery,
} = partnerApi;
