import React from 'react';
import {
  UserOutlined,
  HomeOutlined,
  SettingOutlined,
  QrcodeOutlined,
} from '@ant-design/icons';
import { Link, Route, Routes } from 'react-router-dom';

import LinksPage from 'components/dashboard/components/LinksPage/LinksPage';
import ProjectPage from 'components/dashboard/components/ProjectPage/ProjecPage';
import UsersPage from 'components/dashboard/components/UsersPage/UsersPage';
import MainPage from 'components/dashboard/components/MainPage/MainPage';
import ClientsPage from 'components/dashboard/components/ClientsPage/ClientsPage';

import DashboardLayout from '../components/DashboardLayout/DashboardLayout';
import CreatedPromocodesPage from '../components/CreatedPromocodesPage/CreatedPromocodesPage';
import PromocodesPage from './components/PromocodesPage/PromocodesPage';
import './DashboardOwner.scss';

const sideMenuItems = [
  {
    key: 'home',
    icon: <HomeOutlined />,
    label: <Link to="/dashboard">Главная</Link>,
  },
  {
    key: 'client',
    icon: <SettingOutlined />,
    label: <Link to="client">Мой сайт</Link>,
  },
  {
    key: 'partners',
    icon: <UserOutlined />,
    label: <Link to="partners">Партнеры</Link>,
  },
  {
    key: 'managers',
    icon: <UserOutlined />,
    label: <Link to="managers">Менеджеры по поиску партнеров</Link>,
  },
  {
    key: 'promocodes',
    icon: <QrcodeOutlined />,
    label: <Link to="promocodes">Промокоды</Link>
  },
];

const DashboardOwner = () => (
  <DashboardLayout sidebarItems={sideMenuItems}>
    <Routes>
      <Route path="links/:id" element={<LinksPage />} />
      <Route path="created-promocodes/:id" element={<CreatedPromocodesPage />} />
      <Route path="partners" element={<UsersPage listType="partner" />} />
      <Route path="managers" element={<UsersPage listType="manager" />} />
      <Route path="project/:id" element={<ProjectPage />} />
      <Route path="client" element={<ClientsPage />} />
      <Route path="promocodes" element={<PromocodesPage />} />
      <Route path="*" element={<MainPage />} />
    </Routes>
  </DashboardLayout>
);

export default DashboardOwner;
