import React, { useState } from 'react';

import { useGetTotalStatsQuery } from 'store/user/userApi';
import { CreatedType } from 'types/appType';
import { useTypedSelector } from 'store/store';
import { selectUserRole } from 'store/user/userSlice';
import { getStatsTitleNew, transformTotalStats } from 'utils/stats';
import StatsTotal from 'components/dashboard/components/StatsTotal/StatsTotal';
import { useNotification } from 'hooks/useNotification';

import TotalStatsFilter, { FilteValues } from './Filter/TotalStatsFilter';
import './TotalStats.scss';

const TotalStats = () => {
  const role = useTypedSelector(selectUserRole);
  const [params, setParams] = useState<CreatedType>({
    createdFrom: '',
    createdTo: '',
    role: role || 'admin',
    partnersOnly: false,
  });
  const { data, isFetching, isError } = useGetTotalStatsQuery(params);

  useNotification({
    isError,
    errorMessage: 'Ошибка загрузки данных статистики',
  });

  const totalStat = transformTotalStats(data);
  const handleFilterChange = (filter: FilteValues) =>
    setParams((prev) => ({
      ...prev,
      ...filter,
    }));

  const statsTotalList = Object.entries(totalStat)
    .map(([key, val]) => ({
      name: getStatsTitleNew(key),
      value: val
    }));


  return (
    <div className="total-stats">
      <TotalStatsFilter
        className='total-stats__filter'
        onFilter={handleFilterChange}
        showPartnersOnlyItem={role !== 'manager'}
      />
      <StatsTotal
        title="Ваша статистика"
        isLoading={isFetching}
        statsList={statsTotalList}
      />
    </div>
  );
};

export default TotalStats;
