import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import TextBox from 'components/shared/TextBox/TextBox';
import { bemCn } from 'utils/bem-cn';
import Button from 'components/shared/Button/Button';
import './LoginForm.scss';

const loginSchema = yup.object().shape({
  'user-email': yup.string()
    .email('Введите корректный email')
    .required('Введите email'),
  'user-password': yup.string()
    .min(3, 'Пароль должен быть больше 3-х символов')
    .required('Введите пароль'),
}).required();

export type FormValues = yup.InferType<typeof loginSchema>;

const defaultValues: FormValues = {
  'user-email': '',
  'user-password': '',
};

type FormError = {
  email: string | null;
  password: string | null;
  common: string | null
};

type Props = {
  className?: string;
  onSubmit: (formData: FormValues) => void;
  error?: FormError
  isLoading?: boolean;
};

const LoginForm = ({ className, onSubmit, error, isLoading }: Props) => {
  const { control, handleSubmit, setError } = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(loginSchema),
    mode: 'all',
  });

  useEffect(() => {
    if (error?.email) {
      setError('user-email', {
        type: 'server',
        message: error?.email ?? undefined,
      });
    }
    if (error?.password) {
      setError('user-password', {
        type: 'server',
        message: error?.password ?? undefined,
      });
    }
  }, [error?.email, error?.password]);

  const b = bemCn('dev-login-form');
  return (
    <div className={b(null, className)}>
      <h2 className={b('title')}>Войти</h2>
      <form className={b('form')} action="POST" onSubmit={handleSubmit(onSubmit)}>
        {error?.common && <p className={b('error')}>{error?.common}</p>}
        <TextBox<FormValues>
          className={b('item')}
          control={control}
          type="email"
          name="user-email"
          label="Email:"
          placeholder="username@mail.com"
        />
        <TextBox<FormValues>
          className={b('item')}
          control={control}
          type="password"
          name="user-password"
          label="Пароль:"
          placeholder="*******"
        />
        <Button className={b('submit-btn')} type="submit" disabled={isLoading} isLoading={isLoading}>
          Войти
        </Button>
      </form>
    </div>
  );
};

export default LoginForm;
