import React from 'react';
import {
  UserOutlined,
  HomeOutlined,
  SearchOutlined,
  MessageOutlined,
  QrcodeOutlined,
} from '@ant-design/icons';
import { Link, Route, Routes } from 'react-router-dom';

import IconCoin from 'components/shared/icons/IconCoin';

import MainPage from './components/MainPage/MainPage';
import ProjectPage from './components/ProjectPage/ProjectPage';
import LinksPage from './components/LinksPage/LinksPage';
import UsersPage from './components/UsersPage/UsersPage';
import PromocodesPage from './components/PromocodesPage/PromocodesPage';
import DashboardLayout from '../components/DashboardLayout/DashboardLayout';
import Withdrawals from '../components/Withdrawals/Withdrawals';
import './DashboarPartner.scss';

const sideMenuItems = [
  {
    key: 'home',
    icon: <HomeOutlined />,
    label: <Link to="/dashboard">Главная</Link>,
  },
  {
    key: 'users',
    icon: <UserOutlined />,
    label: <Link to="users">Мои пользователи</Link>
  },
  {
    key: 'project',
    icon: <MessageOutlined />,
    label: <Link to="project">Проекты</Link>
  },
  {
    key: 'link',
    icon: <SearchOutlined />,
    label: <Link to="links">Ссылки</Link>
  },
  {
    key: 'promocodes',
    icon: <QrcodeOutlined />,
    label: <Link to="promocodes">Промокоды</Link>
  },
  // {
  //   key: 'withdrawals',
  //   icon: <IconCoin />,
  //   label: <Link to="withdrawals">Заявки на вывод</Link>
  // },
];

const DashboarPartner = () => (
  <DashboardLayout sidebarItems={sideMenuItems}>
    <Routes>
      <Route path="users" element={<UsersPage />} />
      <Route path="project" element={<ProjectPage />} />
      <Route path="links" element={<LinksPage />} />
      <Route path="promocodes" element={<PromocodesPage />} />
      {/* <Route path="withdrawals" element={<Withdrawals />} /> */}
      <Route path="*" element={<MainPage />} />
    </Routes>
  </DashboardLayout>
);

export default DashboarPartner;
