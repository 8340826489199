import React, { useState, useEffect } from 'react';
import { Button, Form, Select, Modal } from 'antd';

import { useGetPartnerProjectsQuery } from 'store/partner/partnerApi';
import { GetPartnerProjects } from 'store/partner/types';

const initParams = {
  pageSize: 10,
  page: 1
};

type FormFields = {
  id: number;
};

type Props = {
  opened?: boolean;
  loading?: boolean;
  onClose?: () => void;
  onCreate?: (projectId: number) => void;
}

const AddLinksModal = ({ opened, onClose, onCreate, loading }: Props) => {
  const [params, setParams] = useState<GetPartnerProjects>(initParams);
  const { data: projects, isFetching } = useGetPartnerProjectsQuery(params);

  const [form] = Form.useForm<FormFields>();

  useEffect(() => {
    if (projects?.count && projects?.count !== params.pageSize) {
      setParams((prevParams) => ({ ...prevParams, pageSize: projects?.count }));
    }
  }, [projects?.count]);

  return (
    <Modal
      open={opened}
      title="Выберите проект, в котором генерируется новая ссылка"
      onCancel={onClose}
      destroyOnClose
      footer={null}
      style={{ backdropFilter: 'blur(20px)', }}
    >
      <Form<FormFields>
        form={form}
        onFinish={(values) => onCreate?.(values.id)}
        disabled={loading || isFetching}
      >
        <Form.Item label="Проект" name="id" rules={[{ required: true, message: 'Выберите проект' }]}>
          <Select loading={isFetching}>
            {projects?.items.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Добавить
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddLinksModal;
