import React from 'react';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';

import { bemCn } from 'utils/bem-cn';

import './Button.scss';

type Props = {
  children?: string;
  className?: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
  variant?: 'primery' | 'secondary' | 'link' | 'a';
  onClick?: () => void;
  href?: string;
  to?: string;
  disabled?: boolean;
  isLoading?: boolean;
};

const Button = (props: Props) => {
  const {
    children,
    className,
    type = 'button',
    variant = 'primery',
    onClick,
    href,
    to = '',
    disabled,
    isLoading
  } = props;

  const b = bemCn('button');

  if (variant === 'a') {
    return (
      <a className={b({ variant }, className)} href={href} onClick={onClick}>
        {children}
      </a>
    );
  }

  if (variant === 'link') {
    return (
      <Link className={b({ variant }, className)} to={to} onClick={onClick}>
        {children}
      </Link>
    );
  }

  return (
    <button className={b({ variant }, className)} type={type} onClick={onClick} disabled={disabled}>
      {isLoading ? <Spin /> : children}
    </button>
  );
};

export default Button;
