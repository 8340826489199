import { Button, Form, Input, Modal } from 'antd';
import React from 'react';

type FormValue = {
  name: string;
};

type Props = {
  opened?: boolean;
  onClose?: () => void;
  onCreate: (projectName: string) => void;
  loading?: boolean;
}

const CreateProjectModal = ({ opened, onClose, onCreate, loading }: Props) => {
  const [form] = Form.useForm<FormValue>();

  const handleCLose = () => {
    onClose?.();
    form.resetFields();
  };

  return (
    <Modal
      open={opened}
      title="Задайте имя нового проекта"
      onCancel={handleCLose}
      destroyOnClose
      footer={null}
    >
      <Form
        form={form}
        onFinish={(values) => onCreate(values.name)}
        disabled={loading}
      >
        <Form.Item label="Имя проекта" name="name">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Добавить
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateProjectModal;
