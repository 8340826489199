import { Button, Table } from 'antd';
import React from 'react';
import dayjs from 'dayjs';
import { AreaChartOutlined, LinkOutlined, PlusOutlined } from '@ant-design/icons';

import type { PartnerProject } from 'store/partner/types';

type Props = {
  items: PartnerProject[];
  isLoading: boolean;
  defaultPageSize: number;
  itemsCount: number;
  onPageChange: (page: number, pageSize: number) => void;
  onCreateLink?: (project: PartnerProject) => void;
  onProjectStat?: (project: PartnerProject) => void;
};

const ProjectsTable = (props: Props) => {
  const {
    isLoading,
    items,
    defaultPageSize,
    itemsCount,
    onPageChange,
    onCreateLink,
    onProjectStat,
  } = props;


  const tableData = items.map((item) => ({ ...item, key: item.id, }));

  return (
    <Table<PartnerProject>
      dataSource={tableData}
      loading={isLoading}
      size='small'
      scroll={{ x: '100%' }}
      style={{ backdropFilter: 'blur(20px)', }}
      pagination={{
        size: 'default',
        showSizeChanger: true,
        pageSizeOptions: [1, 5, 10, 20, 30, 50, 100],
        defaultPageSize: defaultPageSize,
        position: ['bottomCenter'],
        total: itemsCount,
        onChange: onPageChange,
      }}
    >
      <Table.Column<PartnerProject>
        title=""
        key="x"
        render={(_, record) => (
          <Button
            size='small'
            icon={<AreaChartOutlined />}
            title="Статистика"
            onClick={() => onProjectStat?.(record)}
          />
        )}
      />
      <Table.Column<PartnerProject>
        title="Создан"
        key="created"
        dataIndex="created"
        render={(created: string) => (
          dayjs(created).tz('Europe/Moscow').format('DD.MM.YYYY')
        )}
      />
      <Table.Column<PartnerProject>
        title="id"
        key="id"
        dataIndex="id"
      />
      <Table.Column<PartnerProject>
        title="Название"
        key="name"
        dataIndex="name"
      />
      <Table.Column<PartnerProject>
        title=""
        key="x"
        dataIndex="created"
        render={(_, project) => (
          <Button onClick={() => onCreateLink?.(project)} title='Создать ссылку' size='small'>
            <PlusOutlined />
            <LinkOutlined />
          </Button>
        )}
      />
    </Table>
  );
};

export default ProjectsTable;
