import { Button, Col, DatePicker, Form, Row, Select, Space, Switch } from 'antd';
import React from 'react';
import dayjs from 'dayjs';

import { useGetClientsQuery } from 'store/clients/clientsApi';
import { useTypedSelector } from 'store/store';
import { selectUserRole } from 'store/user/userSlice';

type StatsFormValues = {
  clientId: number;
  createdFrom?: dayjs.Dayjs;
  createdTo?: dayjs.Dayjs;
  partnersOnly: boolean;
};

type OnFilterResp = {
  clientId: number;
  createdFrom?: string;
  createdTo?: string;
  partnersOnly: boolean;
};

type Props = {
  onFilterSubmit: (values: OnFilterResp) => void;
};

const StatsFilter = ({ onFilterSubmit }: Props) => {
  const role = useTypedSelector(selectUserRole);
  const [form] = Form.useForm<StatsFormValues>();
  const { data: sitesList } = useGetClientsQuery(role || 'admin');

  const onFinish = (values: StatsFormValues) => {
    const newValues = {
      clientId: values.clientId,
      createdFrom: values.createdFrom?.tz('Europe/Moscow', true).startOf('day').toISOString(),
      createdTo: values.createdTo?.tz('Europe/Moscow', true).endOf('day').toISOString(),
      partnersOnly: values.partnersOnly,
    };
    onFilterSubmit(newValues);
  };

  const onResetClick = () => {
    form.resetFields();
  };

  const initialValues: StatsFormValues = {
    clientId: 3,
    partnersOnly: true,
  };

  return (
    <Form<StatsFormValues>
      layout="vertical"
      form={form}
      className="filter-form"
      onFinish={onFinish}
      initialValues={initialValues}
      lang="ru"
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} md={8} lg={6}>
          <Form.Item
            className="filter-form__item"
            label="Проект"
            name="clientId"
            required
          >
            <Select
              placeholder="Проект"
            >
              {sitesList?.map((item) => (
                <Select.Option key={item.id} value={item.id}>{item.domain}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Space>
            <Form.Item label="Дата (от)" name="createdFrom" className="filter-form__item">
              <DatePicker format='DD.MM.YYYY' />
            </Form.Item>
            <Form.Item label="Дата (до)" name="createdTo" className="filter-form__item">
              <DatePicker format='DD.MM.YYYY' />
            </Form.Item>
          </Space>
        </Col>
        <Col xs={24} md={8} lg={6}>
          <Form.Item label="Только партнёры" valuePropName="checked" name="partnersOnly">
            <Switch />
          </Form.Item>
          <Form.Item label="Управление" className="filter-form__item filter-form__buttons-wrapper">
            <Button type="primary" className="filter-form__button" htmlType="submit">Применить</Button>
            <Button
              className="filter-form__button"
              onClick={onResetClick}
            >
              Сбросить
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default StatsFilter;
