import React from 'react';

import { useGetUserStatQuery } from 'store/users/usersApi';
import { useTypedSelector } from 'store/store';
import { selectUserRole } from 'store/user/userSlice';
import { createStatsListShort } from 'utils/stats';
import StatsTotal from 'components/dashboard/components/StatsTotal/StatsTotal';

type Props = {
  id: number;
  transacionDates?: {
    transactionFrom?: string;
    transactionTo?: string;
  };
};

const UserStatsList = ({ id, transacionDates }: Props) => {
  const role = useTypedSelector(selectUserRole);
  const { data, isFetching, error } = useGetUserStatQuery({
    id,
    role: role || 'admin',
    createdFrom: transacionDates?.transactionFrom,
    createdTo: transacionDates?.transactionTo,
  });

  const statsList = createStatsListShort(data);

  return (
    <StatsTotal
      statsList={statsList}
      isLoading={isFetching}
    />
  );
};

export default UserStatsList;
