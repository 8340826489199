import React from 'react';
import { Table } from 'antd';

import { StatsInfo } from 'types/statsApi';
import { roundNumber } from 'utils/common';

type Props = {
  loading?: boolean;
  data?: StatsInfo;
}

const StatsTableTotal = ({ data, loading }: Props) => {
  const tableData = data && [{ ...data, key: '0', }];

  return (
    <Table<StatsInfo>
      dataSource={tableData}
      loading={loading}
      pagination={false}
      size='small'
      scroll={{ x: '100%' }}
    >
      <Table.Column<StatsInfo>
        title="Переходы"
        key="numClicks"
        dataIndex="numClicks"
      />
      <Table.Column<StatsInfo>
        title="Регистрации"
        key="numRegistrations"
        dataIndex="numRegistrations"
      />
      <Table.Column<StatsInfo>
        title="Пополнения"
        key="deposits"
        dataIndex="deposits"
        render={roundNumber}
      />
      <Table.Column<StatsInfo>
        title="Пополнения администратором"
        key="adminDeposits"
        dataIndex="adminDeposits"
        render={roundNumber}
      />
      <Table.Column<StatsInfo>
        title="Первые депозиты"
        key="firstDeposits"
        dataIndex="firstDeposits"
        render={roundNumber}
      />
      <Table.Column<StatsInfo>
        title="Сумма ставок"
        key="betsPlaced"
        dataIndex="betsPlaced"
        render={roundNumber}
      />
      <Table.Column<StatsInfo>
        title="Не рассчитано"
        key="betsIngame"
        dataIndex="betsIngame"
        render={roundNumber}
      />
      <Table.Column<StatsInfo>
        title="Проигрыш"
        key="betsLosses"
        dataIndex="betsLosses"
        render={roundNumber}
      />
      <Table.Column<StatsInfo>
        title="Выигрыш /Возврат"
        key="betsWinnings"
        dataIndex="betsWinnings"
        render={roundNumber}
      />
      <Table.Column<StatsInfo>
        title="Прибыль по ставкам"
        key="betsIncome"
        dataIndex="betsIncome"
        render={roundNumber}
      />
      <Table.Column<StatsInfo>
        title="Оборот Inbet"
        key="inbetPlaced"
        dataIndex="inbetPlaced"
        render={roundNumber}
      />
      <Table.Column<StatsInfo>
        title="Выигрыш Inbet"
        key="inbetWinnings"
        dataIndex="inbetWinnings"
        render={roundNumber}
      />
      <Table.Column<StatsInfo>
        title="Прибыль Inbet"
        key="inbetIncome"
        dataIndex="inbetIncome"
        render={roundNumber}
      />
      <Table.Column<StatsInfo>
        title="Оборот Betgames"
        key="betgamesPlaced"
        dataIndex="betgamesPlaced"
        render={roundNumber}
      />
      <Table.Column<StatsInfo>
        title="Выигрыш Betgames"
        key="betgamesWinnings"
        dataIndex="betgamesWinnings"
        render={roundNumber}
      />
      <Table.Column<StatsInfo>
        title="Прибыль Betgames"
        key="betgamesIncome"
        dataIndex="betgamesIncome"
        render={roundNumber}
      />
      <Table.Column<StatsInfo>
        title="Ставки слоты Vegas"
        key="vegasPlaced"
        dataIndex="vegasPlaced"
        render={roundNumber}
      />
      <Table.Column<StatsInfo>
        title="Выигрыш слоты Vegas"
        key="vegasWinnings"
        dataIndex="vegasWinnings"
        render={roundNumber}
      />
      <Table.Column<StatsInfo>
        title="Прибыль слоты Vegas"
        key="vegasIncome"
        dataIndex="vegasIncome"
        render={roundNumber}
      />
      <Table.Column<StatsInfo>
        title="Общий доход"
        key="income"
        dataIndex="income"
        render={roundNumber}
      />
      <Table.Column<StatsInfo>
        title="Личный доход"
        key="revshare"
        dataIndex="revshare"
        render={roundNumber}
      />
    </Table>
  );
};

export default StatsTableTotal;
