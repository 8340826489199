import React, { useState } from 'react';
import { UseControllerProps, useController, FieldValues } from 'react-hook-form';

import { bemCn } from 'utils/bem-cn';

import './TextBox.scss';

type Props<T extends FieldValues> = {
  className?: string;
  label?: string;
  name: string;
  type?:
  | 'email'
  | 'number'
  | 'password'
  | 'search'
  | 'tel'
  | 'text';
  disabled?: boolean;
  placeholder?: string;
  externalErorr?: string | null;
  onChange?: (value?: string) => void;
} & UseControllerProps<T>;

const TextBox = <T extends FieldValues>(props: Props<T>) => {
  const {
    className,
    label,
    name,
    type,
    disabled,
    placeholder,
    externalErorr,
    onChange,
  } = props;
  const { field, fieldState: { error, invalid, isDirty } } = useController(props);

  const [isPassShown, setPassShown] = useState(false);

  const handleShowPassClick = () => {
    setPassShown(prev => !prev);
  };

  const isPassword = type === 'password';

  const b = bemCn('text-box');
  return (
    <div className={b(null, className)}>
      {label && (
        <label className={b('label')} htmlFor={name}>
          {label}
        </label>
      )}
      <p className={b('input-wrapper')}>
        <input
          className={b('input', { invalid, password: isPassword })}
          {...field}
          onChange={evt => {
            field.onChange(evt);
            onChange?.(evt.target.value);
          }}
          id={name}
          type={isPassword && isPassShown ? 'text' : type}
          disabled={disabled}
          placeholder={placeholder}
        />
        {isPassword && (
          <button className={b('pass-show', { active: isPassShown })} type="button" onClick={handleShowPassClick}>
            <span className="visually-hidden">Показать пароль</span>
          </button>
        )}
      </p>
      <p className={b('error')}>
        {error?.message}
      </p>
    </div>
  );
};

export default TextBox;
