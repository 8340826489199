import { Collapse } from 'antd';
import React, { useEffect, useState } from 'react';

import { useTypedSelector } from 'store/store';
import { selectUserRole } from 'store/user/userSlice';
import { useGetUsersQuery } from 'store/users/usersApi';
import { FilterUsersValues, UsersRole } from 'types/appType';
import { defaultPageSize } from 'utils/constants';

import UsersFilter, { FilterValues } from './components/UsersFilter';
import UsersList from './components/UsersList';
import Modals from './components/Modals';

import type { UsersListItem } from 'store/users/types';

const initParams = {
  pageSize: defaultPageSize,
  page: 1,
  ascending: false,
  transactionFrom: undefined,
  transactionTo: undefined,
};

type Props = {
  listType: UsersRole;
};

const UsersPage = ({ listType }: Props) => {
  const [activeModal, setActiveModal] = useState('');
  const [selectedUser, setSelectedUser] = useState<UsersListItem | undefined>();

  const role = useTypedSelector(selectUserRole);
  const [params, setParams] = useState<FilterUsersValues>({
    ...initParams,
    role: role || 'admin',
    usersRole: listType,
  });


  const getUsersParams = { ...params };
  delete getUsersParams.transactionFrom;
  delete getUsersParams.transactionTo;
  const { data, isFetching } = useGetUsersQuery(getUsersParams);

  useEffect(() => {
    setParams((prevState) => ({
      ...prevState,
      usersRole: listType
    }));
  }, [listType]);

  const handlePageChange = (page: number, pageSize: number) =>
    setParams((prevParams) => ({ ...prevParams, page, pageSize }));

  const closeModal = () => {
    setSelectedUser(undefined);
    setActiveModal('');
  };

  const handleDropdownClick = (modal: string, item: UsersListItem) => {
    setActiveModal(modal);
    setSelectedUser(item);
  };

  const handleFilterChange = (filter: FilterValues) => {
    setParams((prev) => ({
      ...prev,
      ...filter,
    }));
  };

  const handleFilterReset = () => {
    setParams({
      ...initParams,
      role: role || 'admin',
      usersRole: listType,
    });
  };

  return (
    <>
      <Collapse
        style={{ marginBottom: 10 }}
        size="small"
        items={[{
          key: '1',
          label: 'Фильтр',
          children: (
            <UsersFilter
              onFilter={handleFilterChange}
              onReset={handleFilterReset}
            />
          )
        }]}
      />
      <UsersList
        users={data?.items ?? []}
        usersTotalCount={data?.count ?? 0}
        isLoading={isFetching}
        onPageChange={handlePageChange}
        onActionsClick={handleDropdownClick}
        transacionDates={{
          transactionFrom: params.transactionFrom,
          transactionTo: params.transactionTo,
        }}
      />
      <Modals
        activeModal={activeModal}
        onClose={closeModal}
        selectedUser={selectedUser}
      />
    </>
  );
};

export default UsersPage;
