import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { CreatedType } from 'types/appType';
import { useTypedSelector } from 'store/store';
import { selectUserRole } from 'store/user/userSlice';
import { useLinkStatsQuery } from 'store/stats/statsApi';
import StatsTableTotal from 'components/shared/StatsTableTotal/StatsTableTotal';

import TotalStatsFilter, { type FilteValues } from '../TotalStats/Filter/TotalStatsFilter';

const LinksPage = () => {
  const role = useTypedSelector(selectUserRole);
  const [params, setParams] = useState<CreatedType>({
    createdFrom: '',
    createdTo: '',
    role: role || 'admin',
  });
  const { id } = useParams();
  const dataParams: { id: string } & CreatedType = {
    createdFrom: params.createdFrom,
    createdTo: params.createdTo,
    role: params.role,
    id: id || ''
  };
  const { data, isFetching } = useLinkStatsQuery(dataParams);

  const handleFilterChange = (filter: FilteValues) =>
    setParams((prev) => ({ ...prev, ...filter }));

  return (
    <div className="total-stats">
      <TotalStatsFilter onFilter={handleFilterChange} />
      <StatsTableTotal
        data={data}
        loading={isFetching}
      />
    </div>
  );
};

export default LinksPage;
