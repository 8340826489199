import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import LoginForm, { FormValues } from 'components/LoginForm/LoginForm';
import { bemCn } from 'utils/bem-cn';
import { selectIsAuthenticated, selectLoginError } from 'store/auth/authSlice';
import { useTypedSelector } from 'store/store';
import { useLoginMutation } from 'store/auth/authApi';

import './LoginPage.scss';

type Props = {
  isAuth: boolean,
};

const LoginPage = ({ isAuth }: Props) => {
  const [login, { isLoading, isError }] = useLoginMutation();
  const errors = useTypedSelector(selectLoginError);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth) {
      navigate('/', { replace: true });
    }
  }, [isAuth]);

  const handleFormSubmit = async (formData: FormValues) => {
    // console.log({
    //   email: formData['user-email'],
    //   password: formData['user-password']
    // });

    await login({
      email: formData['user-email'],
      password: formData['user-password']
    });
  };

  const b = bemCn('dev-login-page');

  return (
    <div className={b()}>
      <LoginForm
        className={b('login-form', { shake: isError })}
        onSubmit={handleFormSubmit}
        error={errors}
        isLoading={isLoading}
      />
    </div>
  );
};

export default LoginPage;
