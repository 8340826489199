import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Table } from 'antd';
import { Link } from 'react-router-dom';

import { defaultPageSize } from 'utils/constants';
import { useTypedSelector } from 'store/store';
import { selectUserRole } from 'store/user/userSlice';
import { useGetUserLinksQuery } from 'store/users/usersApi';
import { FilterUsersValues } from 'types/appType';
import { UserLinksItem } from 'store/users/types';

type Props = {
  id: number;
};

const UserLinks = ({ id }: Props) => {
  const role = useTypedSelector(selectUserRole);
  const [params, setParams] = useState<FilterUsersValues>({
    pageSize: defaultPageSize,
    page: 1,
    role: role || 'admin',
    id
  });

  const { data, isFetching } = useGetUserLinksQuery(params);

  const tableData = data?.items.map((item, i) => ({
    ...item,
    key: item.refCode
  }));

  const handlePageChange = (page: number, pageSize: number) =>
    setParams((prevParams) => ({ ...prevParams, page, pageSize }));

  return (
    <Table<UserLinksItem>
      dataSource={tableData}
      loading={isFetching}
      size='small'
      scroll={{ x: '100%' }}
      pagination={{
        size: 'default',
        showSizeChanger: true,
        pageSizeOptions: [1, 20, 30, 50, 100],
        defaultPageSize,
        position: ['bottomCenter'],
        total: data?.count,
        onChange: handlePageChange,
      }}
    >
      <Table.Column<UserLinksItem>
        title="Создана"
        key="created"
        dataIndex="created"
        render={(created: string) => (
          dayjs(created).tz('Europe/Moscow').format('DD.MM.YYYY')
        )}
      />
      <Table.Column<UserLinksItem>
        title="Ссылка"
        key="link"
        dataIndex="link"
      />
      <Table.Column<UserLinksItem>
        title="projectId"
        key="projectId"
        dataIndex="projectId"
        render={(item: number) => (
          <Link to={`/dashboard/project/${item}`}>{item}</Link>
        )}
      />
      <Table.Column<UserLinksItem>
        title="Имя проекта"
        key="projectName"
        dataIndex="projectName"
      />
      <Table.Column<UserLinksItem>
        title="refCode"
        key="refCode"
        dataIndex="refCode"
        render={(item: string) => (
          <Link to={`/dashboard/links/${item}`}>{item}</Link>
        )}
      />
    </Table>
  );
};

export default UserLinks;
