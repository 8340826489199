import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { CreatedType } from 'types/appType';
import { useProjectStatsQuery } from 'store/stats/statsApi';
import TotalStatsFilter, { FilteValues } from 'components/dashboard/components/TotalStats/Filter/TotalStatsFilter';
import { createStatsListShort } from 'utils/stats';
import { useTypedSelector } from 'store/store';
import { selectUserRole } from 'store/user/userSlice';

import StatsTotal from '../StatsTotal/StatsTotal';

const ProjectPage = () => {
  const role = useTypedSelector(selectUserRole) ?? 'admin';
  const [params, setParams] = useState<CreatedType>({
    createdFrom: '',
    createdTo: '',
    role,
  });
  const { id } = useParams();
  const { data, isFetching } = useProjectStatsQuery({
    ...params,
    id: Number(id),
  });

  const handleFilterChange = (filter: FilteValues) =>
    setParams((prev) => ({
      ...prev,
      ...filter,
    }));

  const statsList = createStatsListShort(data);

  return (
    <div className="total-stats">
      <TotalStatsFilter onFilter={handleFilterChange} />
      <StatsTotal
        statsList={statsList}
        isLoading={isFetching}
      />
    </div>
  );
};

export default ProjectPage;
