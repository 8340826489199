import { Button, Form, Input } from 'antd';
import React from 'react';

import { useNotification } from 'hooks/useNotification';
import { useAddClientsMutation } from 'store/clients/clientsApi';

type Props = {
  closeModal: () => void;
};

const ClientAdd = ({ closeModal }: Props) => {
  const [form] = Form.useForm();
  const [addClient, { isSuccess, isError }] = useAddClientsMutation();

  const onFinish = async (value: { domain: string }) => {
    await addClient(value.domain);
    closeModal();
  };

  useNotification({
    isSuccess,
    isError
  });

  return (
    <Form
      form={form}
      onFinish={onFinish}
    >
      <Form.Item label="domain" name="domain">
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">Сохранить</Button>
      </Form.Item>
    </Form>
  );
};

export default ClientAdd;
