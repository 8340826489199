import React, { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import dayjs from 'dayjs';

import { useTypedSelector } from 'store/store';
import { selectAccountData } from 'store/user/userSlice';
import { getStatsTitleNew, transformTotalStats } from 'utils/stats';
import { useGetClientsQuery, useStatsClientQuery } from 'store/clients/clientsApi';
import { ClientsItem } from 'store/clients/types';
import { useNotification } from 'hooks/useNotification';

import StatsTotal from '../../../components/StatsTotal/StatsTotal';
import StatsFilter from './StatsFilter';

type FilterType = {
  clientId: number;
  createdFrom?: string;
  createdTo?: string;
  partnersOnly: boolean;
};

const initialFilterState: FilterType = {
  clientId: 3,
  createdFrom: undefined,
  createdTo: undefined,
  partnersOnly: true,
};

const StatsPage = () => {
  const userData = useTypedSelector(selectAccountData);
  const { data: clientsList } = useGetClientsQuery(userData.role || 'admin');
  const [filter, setFilter] = useState<FilterType>(initialFilterState);
  const clientsMap = clientsList?.reduce<Record<string, ClientsItem>>((acc, client) => {
    if (client.id) {
      acc[client.id] = client;
    }
    return acc;
  }, {});

  const {
    data,
    isLoading,
    isError,
    isFetching,
  } = useStatsClientQuery(filter, { skip: filter.clientId === undefined });

  useNotification({ isError });

  useEffect(() => {
    if (clientsList && clientsList.length >= 1) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        clientId: clientsList?.[0]?.id ?? 1
      }));
    }
  }, [clientsList]);

  const handleFilterChange = (values: FilterType) =>
    setFilter((prevFilter) => ({
      ...prevFilter,
      ...values
    }));

  const totalStat = transformTotalStats(data);
  const statsTotalList = Object.entries(totalStat)
    .map(([key, val]) => ({
      name: getStatsTitleNew(key),
      value: val
    }));

  const dateFrom = dayjs(filter.createdFrom).tz('Europe/Moscow').format('DD.MM.YY');
  const dateTo = dayjs(filter.createdTo).tz('Europe/Moscow').format('DD.MM.YY');

  const statStartDate = filter.createdFrom ? `c ${dateFrom} ` : '';
  const statEndDate = filter.createdTo ? `- по ${dateTo}` : '';
  const statsTotalTitle = 'Cтатистика '
    + `"${clientsMap?.[filter.clientId]?.domain ?? ''}"`
    + `${statStartDate}`
    + `${statEndDate}`
    + ` (${userData.currency}):`;

  return (
    <>
      <Collapse
        style={{ marginBottom: 10 }}
        size="small"
        items={[{
          key: 'stats-filter',
          label: 'Фильтр',
          children: (
            <StatsFilter
              onFilterSubmit={handleFilterChange}
            />
          )
        }]}
      />
      <StatsTotal
        title={statsTotalTitle}
        isLoading={isLoading || isFetching}
        statsList={statsTotalList}
      />
    </>
  );
};

export default StatsPage;
