import { api } from 'store/api';
import { FilterUsersValues, UserUpdateRequest } from 'types/appType';
import { ToSnakeCase } from 'types/transformTypes';
import { camelToSnakeCase, snakeToCamelCase } from 'utils/transforms';
import { StatsInfo } from 'types/statsApi';
import { Adapter } from 'utils/adapter';

import { UserLinksReponse, UserLinksServer, UsersResponse, UsersResponseServer } from './types';

import type { Promocodes } from 'types/promocodeTypes';

export type GetUserPromocodes = {
  partnerId: number;
  pageSize?: number;
  page?: number;
  ascending?: boolean;
  role: string;
}

const usersApi = api.injectEndpoints({
  endpoints: (build) => ({

    getUsers: build.query<UsersResponse, FilterUsersValues>({
      query: (filter: FilterUsersValues) => ({
        url: `v1/${filter.role}/users`,
        method: 'GET',
        params: camelToSnakeCase<FilterUsersValues, ToSnakeCase<FilterUsersValues>>({
          ...filter,
          role: filter.usersRole ?? ''
        })

      }),
      providesTags: ['Users'],
      transformResponse(response: UsersResponseServer): UsersResponse {
        return {
          count: response.count,
          items: response.items.map((item) => snakeToCamelCase(item))
        };
      }
    }),

    getUserStat: build.query<StatsInfo, {
      id: number;
      role: string;
      createdFrom?: string;
      createdTo?: string;
      pageSize?: number;
      page?: number;
    }>({
      query: (data) => ({
        url: `v1/${data.role}/stats/partner/${data.id}`,
        params: {
          'created_from': data?.createdFrom,
          'created_to': data?.createdTo,
          'page_size': data?.pageSize,
          'page': data?.page
        }
      }),
      providesTags: ['Stats'],
      transformResponse: Adapter.statsInfoToClient,
    }),

    UserUpdate: build.mutation<void, UserUpdateRequest>({
      query: (data: UserUpdateRequest) => ({
        url: `v1/${data.role}/users/${data.userId}`,
        method: 'PUT',
        body: {
          'user_id': data.userId,
          'name': data.name,
          'email': data.email,
          'phone': data.phone,
          'telegram': data.telegram,
          'parent_id': data.parentId,
          'revshare_multiplier': data.revshareMultiplier,
          'role': data.userRole
        }
      }),
      invalidatesTags: ['Users']
    }),

    getUserLinks: build.query<UserLinksReponse, FilterUsersValues>({
      query: (data: FilterUsersValues) => ({
        url: `v1/${data.role}/links/${data.id ?? 0}`,
        method: 'GET',
        params: {
          'page_size': data.pageSize,
          'page': data.page
        }
      }),
      transformResponse(response: UserLinksServer): UserLinksReponse {
        return {
          count: response.count,
          items: response.items.map((item) => snakeToCamelCase(item))
        };
      }
    }),

    getUserPromocodes: build.query<Promocodes, GetUserPromocodes>({
      query: (params) => ({
        url: `v1/${params.role}/promocodes/${params.partnerId}`,
        params: {
          'page_size': params?.pageSize,
          'page': params?.page,
          'ascending': params?.ascending,
        }
      })
    })

  })
});

export const {
  useGetUsersQuery,
  useGetUserStatQuery,
  useUserUpdateMutation,
  useGetUserLinksQuery,
  useGetUserPromocodesQuery,
} = usersApi;
