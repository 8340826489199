import React from 'react';

import { useStatsClientQuery } from 'store/clients/clientsApi';
import { ClientsItem } from 'store/clients/types';
import StatsTableTotal from 'components/shared/StatsTableTotal/StatsTableTotal';

type Props = {
  item: ClientsItem | undefined;
};

const ClientStats = ({ item }: Props) => {
  const { data, isFetching } = useStatsClientQuery({
    clientId: item?.id || 0,
    partnersOnly: false,
  });

  return (
    <StatsTableTotal
      data={data}
      loading={isFetching}
    />
  );
};

export default ClientStats;
