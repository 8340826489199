import { RegistrationRequest } from 'components/auth/RegistrationForm/RegistrationForm';
import { api } from 'store/api';

export interface LoginRequest {
  email: string
  password: string
}

export interface LoginResponse {
  access_token: string,
  token_type: string,
}

export const authApi = api.injectEndpoints({
  endpoints: build => ({
    login: build.mutation<LoginResponse, LoginRequest>({
      query: (data: LoginRequest) => ({
        url: 'v1/auth/login',
        method: 'POST',
        body: {
          email: data.email,
          password: data.password,
        },
      }),
      invalidatesTags: result => {
        return result ? ['Auth'] : [];
      },
    }),

    registration: build.mutation<LoginResponse, RegistrationRequest & { ref_code:string }>({
      query: (data:RegistrationRequest & { ref_code:string }) => ({
        url: 'v1/auth/register',
        method: 'POST',
        body: {
          email: data.email,
          password: data.password,
          name: data.name,
          ref_code: data.ref_code,
          currency: data.currency
        }
      }),
      invalidatesTags: result => {
        return result ? ['Auth'] : [];
      },
    })
  }),
});

export const { useLoginMutation, useRegistrationMutation } = authApi;
