import React, { useEffect } from 'react';
import { Navigate, useParams, useNavigate } from 'react-router-dom';

import { bemCn } from 'utils/bem-cn';
import RegistrationForm, { FormValues } from 'components/RegistrationForm/RegistrationForm';
import { useRegistrationMutation } from 'store/auth/authApi';
import { useTypedSelector } from 'store/store';
import { selectRegisterError } from 'store/auth/authSlice';
import './RegistrationPage.scss';

type Props = {
  isAuth: boolean,
};

const RegistrationPage = ({ isAuth }: Props) => {
  const [registration, { isSuccess, isError, isLoading }] = useRegistrationMutation();
  const navigate = useNavigate();
  const errors = useTypedSelector(selectRegisterError);
  const { id } = useParams();

  useEffect(() => {
    if (isAuth) {
      navigate('/', { replace: true });
    }
  }, [isAuth]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        navigate('/', { replace: true });
      }, 1000);
    }
  }, [isSuccess]);

  const handleFormSubmit = async (formData: FormValues) => {
    // console.log({
    //   email: formData['user-email'],
    //   password: formData['user-password'],
    //   name: formData['user-name'],
    //   currency: formData.currency?.value,
    //   ref_code: id ?? ''
    // });

    await registration({
      email: formData['user-email'],
      password: formData['user-password'],
      name: formData['user-name'],
      currency: formData.currency?.value,
      ref_code: id ?? ''
    });
  };

  const b = bemCn('dev-registration-page');
  return (
    <div className={b()}>
      <RegistrationForm
        className={b('registration-form', { shake: isError })}
        onSubmit={handleFormSubmit}
        error={errors}
        isLoading={isLoading}
        isSuccess={isSuccess}
      />
    </div>
  );
};

export default RegistrationPage;
