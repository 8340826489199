import React, { useState, useEffect } from 'react';

import { useTypedSelector } from 'store/store';
import { selectUserRole } from 'store/user/userSlice';
import { useGetUserStatQuery } from 'store/users/usersApi';
import { defaultPageSize } from 'utils/constants';
import StatsUsersTable from 'components/shared/StatsUsersTable/StatsUsersTable';

type Params = {
  id: number;
  pageSize: number;
  page: number;
  createdFrom?: string;
  createdTo?: string;
  role: string;
  partnersOnly?: boolean | undefined;
};

type Props = {
  id: number;
};

const PartnerUsers = ({ id }: Props) => {
  const role = useTypedSelector(selectUserRole);
  const [params, setParams] = useState<Params>({
    id,
    role: role || 'admin',
    pageSize: defaultPageSize,
    page: 1,
    createdFrom: undefined,
    createdTo: undefined,
  });

  const { data, isFetching } = useGetUserStatQuery(params);

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      id,
      role: role || 'admin'
    }));
  }, [id, role]);

  const handlePageChange = (page: number, pageSize: number) =>
    setParams((prevParams) => ({ ...prevParams, page, pageSize }));

  return (
    <StatsUsersTable
      playerStats={data?.playerStats}
      playerStatsCount={data?.playerStatsCount}
      defaultPageSize={defaultPageSize}
      loading={isFetching}
      onPageChange={handlePageChange}
    />
  );
};

export default PartnerUsers;
