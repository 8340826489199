import React from 'react';
import { Modal } from 'antd';

import { useTypedSelector } from 'store/store';
import { selectUserRole } from 'store/user/userSlice';
import { ClientsItem } from 'store/clients/types';

import ClientsDetails from './ClientsDetails';
import ClientAdd from './ClientAdd';
import ClientStats from './ClientsStats';


type Props = {
  activeModal: string | null;
  onClose: () => void;
  activeClient?: ClientsItem;
}

const Modals = ({ activeModal, activeClient, onClose }: Props) => {
  const role = useTypedSelector(selectUserRole);

  return (
    <>
      <Modal
        open={activeModal === 'details'}
        title={`Информация о клиенте ${activeClient?.domain ?? ''}`}
        onCancel={onClose}
        destroyOnClose
        footer={null}
      >
        <ClientsDetails
          item={activeClient}
          closeModal={onClose}
        />
      </Modal>
      <Modal
        className="clients-list__modal"
        title={`Статистика клиента ${activeClient?.domain ?? ''}`}
        onCancel={onClose}
        open={activeModal === 'stats' && role === 'admin'}
        footer={null}
        width="85%"
      >
        <ClientStats item={activeClient} />
      </Modal>
      <Modal
        open={activeModal === 'add-client' && role === 'admin'}
        title="Добавить клиента"
        onCancel={onClose}
        footer={null}
      >
        <ClientAdd closeModal={onClose} />
      </Modal>
    </>
  );
};

export default Modals;
