import { StatsInfo } from 'types/statsApi';

import { roundNumber } from './common';

export const transformTotalStats = (stats: StatsInfo | undefined) => ({
  numClicks: stats?.numClicks ?? 0,
  numRegistrations: stats?.numRegistrations ?? 0,

  deposits: stats?.deposits ?? 0,
  adminDeposits: stats?.adminDeposits ?? 0,
  firstDeposits: stats?.firstDeposits ?? 0,
  betsPlaced: stats?.betsPlaced ?? 0,
  betsWinnings: stats?.betsWinnings ?? 0,
  betsIngame: stats?.betsIngame ?? 0,
  betsLosses: stats?.betsLosses ?? 0,
  betsIncome: stats?.betsIncome ?? 0,

  inbetIncome: stats?.inbetIncome ?? 0,
  inbetPlaced: stats?.inbetPlaced ?? 0,
  inbetWinnings: stats?.inbetWinnings ?? 0,
  betgamesIncome: stats?.betgamesIncome ?? 0,
  betgamesPlaced: stats?.betgamesPlaced ?? 0,
  betgamesWinnings: stats?.betgamesWinnings ?? 0,
  vegasPlaced: stats?.vegasPlaced ?? 0,
  vegasWinnings: stats?.vegasWinnings ?? 0,
  vegasIncome: stats?.vegasIncome ?? 0,

  income: stats?.income ?? 0,
  revshare: stats?.revshare ?? 0,
});

export const statsTitleNew: Record<string, string> = {
  numClicks: 'Переходы', // Количество переходов,
  numRegistrations: 'Регистрации', // Количество регистраций

  deposits: 'Пополнения',
  adminDeposits: 'Пополнения администратором',
  firstDeposits: 'Первые депозиты',
  betsIncome: 'Прибыль по ставкам',
  betsPlaced: 'Сумма ставок',
  betsWinnings: 'Выигрыш/Возврат', // Выигрыш
  betsLosses: 'Проигрыш',
  betsIngame: 'Не рассчитано',
  inbetIncome: 'Прибыль Inbet',
  inbetPlaced: 'Ставки Inbet', // Оборот Inbet
  inbetWinnings: 'Выигрыш Inbet',
  betgamesIncome: 'Прибыль Betgames',
  betgamesPlaced: 'Ставки Betgames', // Оборот Betgames
  betgamesWinnings: 'Выигрыш Betgames',
  vegasPlaced: 'Ставки слоты Vegas',
  vegasWinnings: 'Выигрыш слоты Vegas',
  vegasIncome: 'Прибыль слоты Vegas',

  income: 'Общий доход',
  revshare: 'Личный доход',
};

export const getStatsTitleNew = (key: string) =>
  statsTitleNew[key] ?? key;

const statTitles: Record<string, string> = {
  numClicks: 'Переходы',
  numRegistrations: 'Регистрации',
  depositsSum: 'Сумма депозитов',
  firstDeposits: 'Первые депозиты',
  betsIncome: 'Прибыль ставки',
  casinoIncome: 'Прибыль Казино',
  revshare: 'Личный доход',
  income: 'Доход компании',
};

export const createStatsListShort = (stats: StatsInfo | undefined) => {
  const statsData = {
    numClicks: stats?.numClicks ?? 0,
    numRegistrations: stats?.numRegistrations ?? 0,
    depositsSum: 0
      + Number(stats?.deposits)
      + Number(stats?.adminDeposits),
    firstDeposits: stats?.firstDeposits ?? 0,
    betsIncome: stats?.betsIncome ?? 0,
    casinoIncome: 0
      + Number(stats?.inbetIncome)
      + Number(stats?.betgamesIncome)
      + Number(stats?.vegasIncome),
    revshare: stats?.revshare ?? 0,
    income: stats?.income ?? 0,
  };

  const statsList = Object.entries(statsData)
    .map(([name, value]) => ({
      name: statTitles[name] ?? '',
      value: roundNumber(value)
    }));

  return statsList;
};
