
import { Button, Form, InputNumber, Typography } from 'antd';

import { useNotification } from 'hooks/useNotification';
import { useCreateRemoteAccoutnMutation } from 'store/user/userApi';
import { handleBackendError } from 'utils/common';

type FormValues = {
  remoteId: number;
}

const errorTitles = {
  'Current account already linked': 'Ваш аккаунт уже связан',
  'Target account not found': 'Указанный аккаунт не найден',
  'Target account already linked': 'Указанный аккаунт уже связан',
};

type Props = {
  closeModal?: () => void;
}

const RemoteIdForm = ({ closeModal }: Props) => {
  const [form] = Form.useForm<FormValues>();
  const [createRemote, {
    isSuccess,
    isError,
    error
  }] = useCreateRemoteAccoutnMutation();

  const onFinish = (value: FormValues) => {
    createRemote(value.remoteId);
  };

  useNotification({
    isSuccess,
    onSuccess: () => {
      form.resetFields();
      closeModal?.();
    },
    isError,
    errorMessage: handleBackendError(error, errorTitles)
  });

  return (
    <Form form={form} onFinish={onFinish}>
      <Form.Item>
        <Typography.Text>
          Необходимо указать Id аккауна зарегистрированного на сайте букмекерской конторы
        </Typography.Text>
      </Form.Item>
      <Form.Item label="ID аккаунта" name="remoteId"
        rules={[{ required: true, message: 'Введите id аккаунта', type: 'number' }]}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">Сохранить</Button>
      </Form.Item>
    </Form>
  );
};

export default RemoteIdForm;
