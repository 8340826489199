import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import TextBox from 'components/shared/TextBox/TextBox';
import { bemCn } from 'utils/bem-cn';
import Button from 'components/shared/Button/Button';
import Select from 'components/shared/Select/Select';
import { useGetCurrenciesQuery } from 'store/app/appApi';
import { RegisterErrors } from 'store/auth/types';
import './RegistrationForm.scss';

const regScheme = yup.object().shape({
  'user-name': yup.string()
    .required('Введите имя')
    .min(3, 'Имя должно содержать больше 3-х символов'),
  'user-email': yup.string()
    .required('Введите email')
    .email('Введите корректный email'),
  'user-password': yup.string()
    .required('Введите пароль')
    .min(8, 'Пароль должен содержать не меньше 8-ми символов'),
  'user-password-reapeat': yup.string()
    .required('Введите пароль')
    .min(8, 'Пароль должен содержать не меньше 8-ми символов')
    .oneOf([yup.ref('user-password')], 'Пароли должны совподать'),
  currency: yup.object().shape({
    value: yup.string().required('Value is undefined'),
    label: yup.string().required('Label is undefined'),
  }).required('Выберете валюту')
});

export type FormValues = yup.InferType<typeof regScheme>;

type Props = {
  className?: string;
  onSubmit: (formData: FormValues) => void;
  error?: RegisterErrors;
  isLoading?: boolean;
  isSuccess?: boolean;
};

const RegistrationForm = (props: Props) => {
  const { className, onSubmit, error, isLoading: isFormLoading, isSuccess } = props;
  const { curencyOptions, isCurenciesLoading } = useGetCurrenciesQuery(undefined, {
    selectFromResult: ({ data = [], isLoading }) => ({
      curencyOptions: data.map((item) => ({ value: item, label: item })),
      isCurenciesLoading: isLoading,
    })
  });
  const { control, handleSubmit, trigger, setValue, setError, reset } = useForm<FormValues>({
    defaultValues: {
      'user-name': '',
      'user-email': '',
      'user-password': '',
      'user-password-reapeat': '',
      currency: curencyOptions[0],
    },
    resolver: yupResolver(regScheme),
    mode: 'all',
    criteriaMode: 'all'
  });

  useEffect(() => {
    setValue('currency', curencyOptions[0]);
  }, [curencyOptions.length]);

  useEffect(() => {
    if (error?.email) {
      setError('user-email', {
        type: 'server',
        message: error?.email
      });
    }
    if (error?.name) {
      setError('user-name', {
        type: 'server',
        message: error?.name
      });
    }
    if (error?.password) {
      setError('user-password', {
        type: 'server',
        message: error?.password
      });
    }
    if (error?.currency) {
      setError('currency', {
        type: 'server',
        message: error?.currency
      });
    }
  }, [error?.email, error?.name, error?.password, error?.currency]);

  useEffect(() => {
    if (isSuccess) {
      reset();
    }
  }, [isSuccess]);

  const b = bemCn('dev-registration-form');
  return (
    <div className={b(null, className)}>
      <h2 className={b('title')}>Регистрация</h2>
      <form className={b('form')} action="POST" onSubmit={handleSubmit(onSubmit)}>
        {isSuccess && <p className={b('success')}>Успешная регистрация!</p>}
        {error?.common && <p className={b('error')}>{error?.common}</p>}
        {error?.ref_code && <p className={b('error')}>{error?.ref_code}</p>}
        <TextBox<FormValues>
          className={b('item')}
          control={control}
          label="Имя:*"
          name="user-name"
          type="text"
          placeholder="Ваше имя"
        />
        <TextBox<FormValues>
          className={b('item')}
          control={control}
          label="Email:*"
          name="user-email"
          type="email"
          placeholder="username@mail.com"
        />
        <TextBox<FormValues>
          className={b('item')}
          control={control}
          label="Пароль:*"
          name="user-password"
          type="password"
          placeholder="*****"
          onChange={async () => {
            await trigger('user-password-reapeat');
          }}
        />
        <TextBox<FormValues>
          className={b('item')}
          control={control}
          label="Повторите пароль:*"
          name="user-password-reapeat"
          type="password"
          placeholder="*****"
        />
        <Select<FormValues>
          className={b('item')}
          control={control}
          options={curencyOptions}
          placeholder="Валюта"
          label="Валюта:*"
          isLoading={isCurenciesLoading}
          name="currency"
        />
        <Button className={b('submit-btn')} type="submit" disabled={isFormLoading} isLoading={isFormLoading}>
          Зарегистрироваться
        </Button>
      </form>
    </div>
  );
};

export default RegistrationForm;
