const toSnake = (str: string): string => str.replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`);

const toCamel = (s: string): string => {
  return s.replace(/([-_][a-z])/ig, $1 => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

export const camelToSnakeCase = <T, R>(obj: T) :R => {
  const r = Object.keys(obj).reduce((acc, key) => {
    return {
      ...acc,
      [toSnake(key) as keyof T]: obj[key as keyof T],
    };
  }, {});
  return (r as R);
};

export const snakeToCamelCase = <T, R>(obj: T) :R => {
  const r = Object.keys(obj).reduce((acc, key) => {
    return {
      ...acc,
      [toCamel(key) as keyof T]: obj[key as keyof T],
    };
  }, {});
  return (r as R);
};
