import { Button, Col, DatePicker, Form, Row, Space, Switch } from 'antd';
import React from 'react';
import dayjs from 'dayjs';

interface FormValue {
  partnersOnly?: boolean;
  createdFrom?: dayjs.Dayjs;
  createdTo?: dayjs.Dayjs;
}

export interface FilteValues {
  createdFrom: string;
  createdTo: string;
  partnersOnly?: boolean;
}

type Props = {
  onFilter: (filter: FilteValues) => void;
  showPartnersOnlyItem?: boolean;
  className?: string;
};

const TotalStatsFilter = ({ onFilter, showPartnersOnlyItem, className }: Props) => {
  const [form] = Form.useForm();

  const onFinish = (values: FormValue) => {
    const createdFrom = values.createdFrom?.tz('Europe/Moscow', true).startOf('day').toISOString();
    const createdTo = values.createdTo?.tz('Europe/Moscow', true).endOf('day').toISOString();
    onFilter({
      createdFrom: createdFrom || '',
      createdTo: createdTo || '',
      partnersOnly: values.partnersOnly,
    });
  };

  const initialValues: FormValue = {};

  if (showPartnersOnlyItem) {
    initialValues.partnersOnly = false;
  }

  return (
    <Form
      layout="vertical"
      form={form}
      className={className}
      onFinish={onFinish}
      initialValues={initialValues}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col>
          <Space>
            <Form.Item label="Дата (от)" name="createdFrom" className="filter-form__item">
              <DatePicker format='DD.MM.YYYY' />
            </Form.Item>
            <Form.Item label="Дата (до)" name="createdTo" className="filter-form__item">
              <DatePicker format='DD.MM.YYYY' />
            </Form.Item>
          </Space>
        </Col>
        {showPartnersOnlyItem && (
          <Col>
            <Form.Item label="Только партнёры" valuePropName="checked" name="partnersOnly">
              <Switch />
            </Form.Item>
          </Col>
        )}
        <Col style={{ alignSelf: 'flex-end' }}>
          <Form.Item>
            <Button type="primary" className="filter-form__button" htmlType="submit">
              Применить
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default TotalStatsFilter;
