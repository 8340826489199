import React, { useState } from 'react';


import { useGetPartnerTotalStatsQuery } from 'store/partner/partnerApi';
import StatsTotal from 'components/dashboard/components/StatsTotal/StatsTotal';
import { getStatsTitleNew, transformTotalStats } from 'utils/stats';
import { useNotification } from 'hooks/useNotification';

import TotalStatsFilter, { type FilterValues } from './components/Filter/TotalStatsFilter';

import type { GetPartnerTotalStats } from 'store/partner/types';
import './TotalStats.scss';
const initParams = {
  createdFrom: undefined,
  createdTo: undefined,
};

const TotalStats = () => {
  const [params, setParams] = useState<GetPartnerTotalStats>(initParams);
  const { data, isFetching, isError } = useGetPartnerTotalStatsQuery(params);

  useNotification({
    isError,
    errorMessage: 'Получены не все данные, один или несколько клиентов не доступны'
  });

  const handleFilterChange = (filter: FilterValues) => setParams(filter);

  const totalStat = transformTotalStats(data);
  const statsTotalList = Object.entries(totalStat)
    .map(([key, val]) => ({
      name: getStatsTitleNew(key),
      value: val
    }));

  return (
    <div className="total-stats">
      <TotalStatsFilter onFilter={handleFilterChange} />
      <StatsTotal
        title="Ваша статистика"
        isLoading={isFetching}
        statsList={statsTotalList}
      />
    </div>
  );
};

export default TotalStats;
