import { ToSnakeCase } from 'types/transformTypes';

export type UsersListItem = {
  created: string,
  currency: string,
  email: string,
  id: number,
  name: string,
  parentId: number,
  phone: string,
  revshareMultiplier: number,
  role: string,
  telegram: string,
  userRole: string
};

export type UsersResponse = {
  count: number,
  items: UsersListItem[]
};

export type UsersResponseServer = {
  count:number,
  items: ToSnakeCase<UsersListItem>[]
};

export type UserLinksItem = {
  created: string,
  id: number,
  link: string,
  projectId: number,
  projectName: string,
  refCode: string
};

export interface UserLinksReponse {
  count: number,
  items: UserLinksItem[]
}

export interface UserLinksServer {
  count:number,
  items: ToSnakeCase<UserLinksItem>[]
}

export const RolesChangeAdmin = [
  {
    title: 'Руководитель',
    name: 'owner'
  },
  {
    title: 'Менеджер',
    name: 'manager'
  },
  {
    title: 'Партнер',
    name: 'partner'
  }
];

export const RolesChangeOwner = [
  {
    title: 'Менеджер',
    name: 'manager'
  },
  {
    title: 'Партнер',
    name: 'partner'
  }
];
