import { Role, WithdrawalStatus } from 'store/user/types';

export const ADMIN_ID = 10000;

export const roles : Role[] = ['admin', 'owner', 'manager', 'partner'];

export const rolesTitles: Record<Role, string> = {
  admin: 'Администратор',
  owner: 'Руководитель',
  manager: 'Менеджер',
  partner: 'Партнер'
};

export const withdrawalStatusTitle: Record<WithdrawalStatus, string> = {
  created: 'Создана',
  approved: 'Одобрена',
  declined: 'Отклонена',
  canceled: 'Отменена',
};

export const withdrawalStatusColor: Record<WithdrawalStatus, string> = {
  created: 'default',
  approved: 'success',
  declined: 'error',
  canceled: 'warning',
};

export const defaultPageSize = 30;
