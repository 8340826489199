import React from 'react';
import {
  UserOutlined,
  HomeOutlined,
  SettingOutlined,
  BarChartOutlined
} from '@ant-design/icons';
import { Link, Route, Routes } from 'react-router-dom';

import DashboardLayout from 'components/dashboard/components/DashboardLayout/DashboardLayout';
import LinksPage from 'components/dashboard/components/LinksPage/LinksPage';
import ProjectPage from 'components/dashboard/components/ProjectPage/ProjecPage';
import UsersPage from 'components/dashboard/components/UsersPage/UsersPage';
import ClientsPage from 'components/dashboard/components/ClientsPage/ClientsPage';
import MainPage from 'components/dashboard/components/MainPage/MainPage';

import StatsPage from './components/StatsPage/StatsPage';
import CreatedPromocodesPage from '../components/CreatedPromocodesPage/CreatedPromocodesPage';
import './DashboardAdmin.scss';

const sideMenuItems = [
  {
    key: 'home',
    icon: <HomeOutlined />,
    label: <Link to="/dashboard">Главная</Link>,
  },
  {
    key: 'clients',
    icon: <SettingOutlined />,
    label: <Link to="clients">Сайты</Link>,
  },
  {
    key: 'owners',
    icon: <UserOutlined />,
    label: <Link to="owners">Руководители проектов</Link>,
  },
  {
    key: 'managers',
    icon: <UserOutlined />,
    label: <Link to="managers">Менеджеры по привлечению партнеров</Link>,
  },
  {
    key: 'partners',
    icon: <UserOutlined />,
    label: <Link to="partners">Партнеры</Link>,
  },
  {
    key: 'stats',
    icon: <BarChartOutlined />,
    label: <Link to="stats">Статистика</Link>,
  },
];

const DashboardAdmin = () => (
  <DashboardLayout sidebarItems={sideMenuItems}>
    <Routes>
      <Route path="clients" element={<ClientsPage />} />
      <Route path="owners" element={<UsersPage listType="owner" />} />
      <Route path="managers" element={<UsersPage listType="manager" />} />
      <Route path="partners" element={<UsersPage listType="partner" />} />
      <Route path="stats" element={<StatsPage />} />
      <Route path="links/:id" element={<LinksPage />} />
      <Route path="created-promocodes/:id" element={<CreatedPromocodesPage />} />
      <Route path="project/:id" element={<ProjectPage />} />
      <Route path="*" element={<MainPage />} />
    </Routes>
  </DashboardLayout>
);

export default DashboardAdmin;
