import { createSlice } from '@reduxjs/toolkit';


type AppDataState = {
  isSideBarOpened: boolean;
}

const initialState: AppDataState = {
  isSideBarOpened: false,
};

export const appData = createSlice({
  name: 'APP_DATA',
  initialState,
  reducers: {
    toggleSideBar(state) {
      state.isSideBarOpened = !state.isSideBarOpened;
    }
  },
});

export const {
  toggleSideBar
} = appData.actions;
