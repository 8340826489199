import React, { useState } from 'react';
import { Typography } from 'antd';

import { PartnerLink, GetPartnerLiskStats } from 'store/partner/types';
import { useGetPartnerLinkStatsQuery } from 'store/partner/partnerApi';
import ShortStatsModal, { type FilterValues } from 'components/shared/ShortStatsModal/ShortStatsModal';

const initParams = {
  pageSize: 10,
  page: 1,
  createdFrom: undefined,
  createdTo: undefined,
};

type Params = Omit<GetPartnerLiskStats, 'refCode'>

type Props = {
  opened?: boolean;
  onClose?: () => void;
  linkItem?: PartnerLink | null;
}

const LinkStatsModal = ({ opened, linkItem, onClose }: Props) => {
  const [params, setParams] = useState<Params>(initParams);

  const { data, isFetching } = useGetPartnerLinkStatsQuery({
    ...params,
    refCode: linkItem?.refCode ?? '',
  }, { skip: !opened || !linkItem?.refCode });

  const handlePageChange = (page: number, pageSize: number) =>
    setParams((prevParams) => ({ ...prevParams, page, pageSize }));

  const handleOnFilter = ({ createdFrom, createdTo }: FilterValues) => {
    setParams({
      ...initParams,
      createdFrom: createdFrom,
      createdTo: createdTo,
    });
  };

  const resetParams = () =>
    setParams(initParams);

  const handleClose = () => {
    onClose?.();
    resetParams();
  };

  return (
    <ShortStatsModal
      title='Статистика по реферальному коду'
      stats={data}
      opened={opened}
      loading={isFetching}
      onClose={handleClose}
      onFilter={handleOnFilter}
      onFilterReset={resetParams}
      onPageChange={handlePageChange}
      renderDescription={() => (
        <>
          <Typography.Paragraph>
            <b>Проект:</b> <i>{linkItem?.projectName}</i>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <b>Код:</b> <i>{linkItem?.refCode}</i>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <b>Создан:</b> <i>{linkItem?.created}</i>
          </Typography.Paragraph>
        </>
      )}
    />
  );
};

export default LinkStatsModal;
