import type { AuthState } from './types';

export const initialLoginError = {
  email: null,
  password: null,
  common: null,
};
export const initialRegisterError = {
  email: null,
  password: null,
  name: null,
  common: null,
  currency: null,
  ref_code: null
};

export const initialAuthState: AuthState = {
  token: null,
  isAuthenticated: false,
  loginError: initialLoginError,
  registerErrors: initialRegisterError
};
