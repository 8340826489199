import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Table } from 'antd';
import { Link } from 'react-router-dom';

import { GetUserPromocodes, useGetUserPromocodesQuery } from 'store/users/usersApi';
import { useTypedSelector } from 'store/store';
import { selectUserRole } from 'store/user/userSlice';

import type { Promocode } from 'types/promocodeTypes';

const initParams = {
  pageSize: 20,
  page: 1,
  ascending: false,
};

type Props = {
  id: number;
};

const UserPromocodes = ({ id }: Props) => {
  const role = useTypedSelector(selectUserRole);
  const [params, setParams] = useState<GetUserPromocodes>({
    ...initParams,
    partnerId: id,
    role: role || 'admin',
  });

  const { data } = useGetUserPromocodesQuery(params);

  const tableData = data?.items.map((item) => ({ ...item, key: item.id }));

  const handlePageChange = (page: number, pageSize: number) =>
    setParams((prevParams) => ({ ...prevParams, page, pageSize }));

  return (
    <Table<Promocode>
      dataSource={tableData}
      size='small'
      scroll={{ x: '100%' }}
      pagination={{
        size: 'default',
        showSizeChanger: true,
        pageSizeOptions: [1, 5, 10, 20, 30, 50, 100],
        defaultPageSize: 20,
        position: ['bottomCenter'],
        total: data?.count,
        onChange: handlePageChange,
      }}
    >
      <Table.Column<Promocode>
        title="ID"
        key="id"
        dataIndex="id"
        render={(item: string) => (
          <Link to={`/dashboard/created-promocodes/${item}`}>{item}</Link>
        )}
      />
      <Table.Column<Promocode>
        title="Название"
        key="name"
        dataIndex="name"
      />
      <Table.Column<Promocode>
        title="Создан"
        key="created"
        dataIndex="created"
        render={(created: string) => (
          dayjs(created).tz('Europe/Moscow').format('DD.MM.YYYY')
        )}
      />
    </Table>
  );
};

export default UserPromocodes;
