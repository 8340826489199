import React from 'react';
import { Card, Spin, Statistic, Typography } from 'antd';

import { roundNumber } from 'utils/common';
import './StatsTotal.scss';

type Props = {
  statsList?: { name: string; value: number }[];
  isLoading?: boolean;
  title?: string;
};

const StatsTotal = ({ statsList, isLoading, title }: Props) => (
  <div className="stats-total">
    <Typography.Title level={3} style={{ marginTop: '20px' }}>
      {title}
    </Typography.Title>
    <Spin spinning={isLoading}>
      <div className="stats-total__list">
        {statsList?.map((item) => (
          <Card key={item.name} size="small" bodyStyle={{ padding: 10 }} style={{ backdropFilter: 'blur(20px)', }} >
            <Statistic
              title={item.name}
              value={roundNumber(item.value)}
              groupSeparator=""
              decimalSeparator=","
              valueStyle={{ fontSize: 14, fontWeight: 500 }}
              loading={isLoading}
            />
          </Card>
        ))}
      </div>
    </Spin>
  </div>
);

export default StatsTotal;
