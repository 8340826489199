import React from 'react';

import TotalStats from '../TotalStats/TotalStats';
import './MainPage.scss';

const MainPage = () => (
  <TotalStats />
);

export default MainPage;
