import { api } from 'store/api';

type WithdrawalLimits = {
  [currency: string]: number;
}

export const appAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getCurrencies: builder.query<string[], void>({
      query: () => 'v1/currencies'
    }),

    getWithdrawalLimits: builder.query<WithdrawalLimits, void>({
      query: () => 'v1/withdrawal_limits'
    })
  })
});

export const {
  useGetCurrenciesQuery,
  useGetWithdrawalLimitsQuery,
} = appAPI;
