import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { UseControllerProps, useController, FieldValues } from 'react-hook-form';

import { bemCn } from 'utils/bem-cn';

import './Select.scss';

type SelectOption = {
  value: string;
  label: string;
};

type Props<T extends FieldValues> = {
  className?: string;
  label?: string;
  options?: SelectOption[];
  placeholder?: string;
  defaultValue?: SelectOption;
  isDisabled?: boolean;
  hideSelectedOptions?: boolean;
  isLoading?: boolean;
  value?: SelectOption;
  required?: boolean;
  externalErorr?: string | null;
} & UseControllerProps<T>;

const Select = <T extends FieldValues>({ className, label, isLoading, externalErorr, ...props }: Props<T>) => {
  const { field, fieldState: { error, invalid } } = useController(props);

  const b = bemCn('dev-select');
  return (
    <div className={b(null, className)}>
      {label && <p className={b('label')}>{label}</p>}
      <ReactSelect
        {...field}
        {...props}
        id={props.name}
        className={b('container', { invalid })}
        classNamePrefix={b()}
        isSearchable={false}
        menuPlacement="auto"
      />
      <p className={b('error')}>
        {error?.message}
      </p>
    </div>
  );
};

export default Select;
