import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { BackendError } from 'store/auth/utils';

import type { SerializedError } from '@reduxjs/toolkit';


export const roundNumber = (num: number) => Math.floor((num * 100)) / 100;

export const handleBackendError = (
  error: SerializedError | FetchBaseQueryError | undefined,
  errorTitles?: Record<string, string>
) => {
  if (!error) {
    return error;
  }

  if (error && 'status' in error && typeof error.status === 'number') {
    if (error.status >= 500) {
      return 'Серверная ошибка';
    }
    const {detail} = error.data as BackendError;
    if (typeof detail === 'string') {
      return errorTitles?.[detail] ?? detail;
    }
  }

  return 'Неизвестная ошибка';
};
