import React from 'react';
import {
  UserOutlined,
  HomeOutlined,
} from '@ant-design/icons';
import { Link, Route, Routes } from 'react-router-dom';

import MainPage from 'components/dashboard/components/MainPage/MainPage';
import IconCoin from 'components/shared/icons/IconCoin';

import UsersPage from './components/UsersPage/UsersPage';
import LinksPage from './components/LinksPage/LinksPage';
import CreatedPromocodesPage from '../components/CreatedPromocodesPage/CreatedPromocodesPage';
import ProjectPage from './components/ProjectPage/ProjecPage';
import DashboardLayout from '../components/DashboardLayout/DashboardLayout';
import Withdrawals from '../components/Withdrawals/Withdrawals';
import './DashboardManager.scss';

const sideMenuItems = [
  {
    key: 'home',
    icon: <HomeOutlined />,
    label: <Link to="/dashboard">Главная</Link>,
  },
  {
    key: 'partners',
    icon: <UserOutlined />,
    label: <Link to="partners">Мои партнеры</Link>,
  },
  // {
  //   key: 'withdrawals',
  //   icon: <IconCoin />,
  //   label: <Link to="withdrawals">Заявки на вывод</Link>
  // },
];

const DashboardManager = () => (
  <DashboardLayout sidebarItems={sideMenuItems}>
    <Routes>
      <Route path="partners" element={<UsersPage listType="partner" />} />
      <Route path="links/:id" element={<LinksPage />} />
      <Route path="created-promocodes/:id" element={<CreatedPromocodesPage />} />
      {/* <Route path="withdrawals" element={<Withdrawals />} /> */}
      <Route path="project/:id" element={<ProjectPage />} />
      <Route path="*" element={<MainPage />} />
    </Routes>
  </DashboardLayout>
);

export default DashboardManager;
