import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';

import { useTypedSelector } from 'store/store';
import { selectUserRole } from 'store/user/userSlice';


type Props = {
  onClick: (key: string) => void;
  itemRole: string;
}

const ActionsDropdown = ({ onClick, itemRole }: Props) => {
  const role = useTypedSelector(selectUserRole);
  const handleDropdownClick: MenuItemType['onClick'] = (info) => onClick(info.key);

  let items: MenuItemType[] = [
    {
      key: 'details',
      label: 'Редактировать',
      onClick: handleDropdownClick,
    },
    {
      key: 'link-user',
      label: 'Посмотреть ссылки',
      onClick: handleDropdownClick,
    },
    {
      key: 'partner-users-list',
      label: 'Список пользователей',
      onClick: handleDropdownClick,
    },
    {
      key: 'promocode-user',
      label: 'Посмотреть промокоды',
      onClick: handleDropdownClick,
    }
  ];

  if (role !== 'admin' && role !== 'owner') {
    items = items.filter((li) => li.key !== 'details' && li.key !== 'partner-users-list');
  }

  if (itemRole !== 'partner') {
    items = items.filter((li) =>
      li.key !== 'link-user'
      && li.key !== 'partner-users-list'
      && li.key !== 'promocode-user'
    );
  }

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <Button icon={<MoreOutlined />} size='small' />
    </Dropdown>
  );
};

export default ActionsDropdown;
