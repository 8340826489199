import { Button, Table, Typography } from 'antd';
import React from 'react';
import dayjs from 'dayjs';
import { AreaChartOutlined } from '@ant-design/icons';

import { PartnerLink } from 'store/partner/types';

const { Text } = Typography;

type Props = {
  items?: PartnerLink[];
  loading?: boolean;
  defaultPageSize: number;
  itemsCount: number;
  onPageChange: (page: number, pageSize: number) => void;
  onLinkStats?: (linkItem: PartnerLink) => void;
}

const LinksTable = (props: Props) => {
  const {
    items,
    loading,
    defaultPageSize,
    itemsCount,
    onPageChange,
    onLinkStats
  } = props;

  const tableData = items?.map((item) => ({ ...item, key: item.refCode }));

  return (
    <Table<PartnerLink>
      dataSource={tableData}
      loading={loading}
      size='small'
      scroll={{ x: 1100 }}
      style={{ backdropFilter: 'blur(20px)', }}
      pagination={{
        size: 'default',
        showSizeChanger: true,
        pageSizeOptions: [1, 3, 5, 10, 20, 30, 50, 100],
        defaultPageSize: defaultPageSize,
        position: ['bottomCenter'],
        total: itemsCount,
        onChange: onPageChange,
      }}
    >
      <Table.Column<PartnerLink>
        title=""
        key="x"
        render={(_, linkItem) => (
          <Button
            size='small'
            icon={<AreaChartOutlined />}
            title="Статистика"
            onClick={() => onLinkStats?.(linkItem)}
          />
        )}
      />
      <Table.Column<PartnerLink>
        title="Создана"
        key="created"
        dataIndex="created"
        render={(created: string) => (
          dayjs(created).tz('Europe/Moscow').format('DD.MM.YYYY')
        )}
      />
      <Table.Column<PartnerLink>
        title="Ваша ссылка"
        key="link"
        dataIndex="link"
        render={(link: string) => <Text copyable>{link}</Text>}
      />
      <Table.Column<PartnerLink>
        title="Для проекта"
        key="projectName"
        dataIndex="projectName"
      />
    </Table>
  );
};

export default LinksTable;
