import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { CreatedType } from 'types/appType';
import { useTypedSelector } from 'store/store';
import { selectUserRole } from 'store/user/userSlice';
import { usePromocodeStatsQuery } from 'store/stats/statsApi';
import TotalStatsFilter, { type FilteValues } from 'components/dashboard/components/TotalStats/Filter/TotalStatsFilter';
import { createStatsListShort } from 'utils/stats';

import StatsTotal from '../StatsTotal/StatsTotal';

const CreatedPromocodesPage = () => {
  const role = useTypedSelector(selectUserRole) ?? 'admin';
  const [params, setParams] = useState<CreatedType>({
    createdFrom: '',
    createdTo: '',
    role,
  });
  const { id = '' } = useParams();
  const { data, isFetching } = usePromocodeStatsQuery({ ...params, id });

  const handleFilterChange = (filter: FilteValues) =>
    setParams((prev) => ({
      ...prev,
      ...filter,
    }));

  const statsList = createStatsListShort(data);

  return (
    <div className="total-stats">
      <TotalStatsFilter onFilter={handleFilterChange} />
      <StatsTotal
        statsList={statsList}
        isLoading={isFetching}
      />
    </div>
  );
};
export default CreatedPromocodesPage;
