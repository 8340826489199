import { Button, Layout, Menu, Typography } from 'antd';


import { rolesTitles } from 'utils/constants';
import { useTypedSelector } from 'store/store';
import { selectUserRole } from 'store/user/userSlice';
import { useConfigProvider } from 'context/configProvider';
import IconMoon from 'components/shared/icons/IconMoon';
import IconSun from 'components/shared/icons/IconSun';

import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import './DashboardSidebar.scss';


type Props = {
  menuItems?: ItemType[];
  isCollapsed?: boolean;
  onCollapsed?: (collapsed: boolean) => void;
}

const DashboardSidebar = ({ menuItems, isCollapsed, onCollapsed }: Props) => {
  const role = useTypedSelector(selectUserRole) || 'partner';
  const { mode, setMode } = useConfigProvider();
  return (
    <Layout.Sider
      trigger={null}
      breakpoint="xl"
      collapsible
      collapsed={isCollapsed}
      onCollapse={onCollapsed}
      defaultCollapsed={false}
      collapsedWidth="0"
      zeroWidthTriggerStyle={{
        top: 12,
        left: 0,
      }}
      style={{ backdropFilter: 'blur(20px)', }}
    >
      <Typography.Title level={3} className='logo'>
        {rolesTitles[role]}
      </Typography.Title>
      <Menu
        mode="vertical"
        items={menuItems}
      />
      <Button
        type='default'
        icon={mode === 'light' ? <IconMoon /> : <IconSun />}
        onClick={() => setMode(mode === 'light' ? 'dark' : 'light')}
        style={{ marginLeft: 20, marginTop: 20 }}
      />
    </Layout.Sider>
  );
};

export default DashboardSidebar;
