import React from 'react';
import dayjs from 'dayjs';
import { Table } from 'antd';

import { defaultPageSize } from 'utils/constants';

import UserStatsList from './UserStatsList';
import ActionsDropdown from './ActionsDropdown';

import type { UsersListItem } from 'store/users/types';

type Props = {
  users: UsersListItem[];
  usersTotalCount: number;
  isLoading: boolean;
  transacionDates?: {
    transactionFrom?: string;
    transactionTo?: string;
  };
  onPageChange: (page: number, pageSize: number) => void;
  onActionsClick: (modal: string, item: UsersListItem) => void;
};

const UsersList = (props: Props) => {
  const {
    isLoading,
    transacionDates,
    users,
    usersTotalCount,
    onPageChange,
    onActionsClick,
  } = props;

  const tableData = users.map((item) => ({ ...item, key: item.id }));

  const expandedRowRender = (record: UsersListItem) =>
    <UserStatsList id={record.id} transacionDates={transacionDates} />;

  return (
    <Table<UsersListItem>
      dataSource={tableData}
      loading={isLoading}
      size='small'
      expandable={{ expandedRowRender }}
      scroll={{ x: '100%' }}
      style={{ backdropFilter: 'blur(20px)', }}
      pagination={{
        size: 'default',
        showSizeChanger: true,
        pageSizeOptions: [1, 20, 30, 50, 100],
        defaultPageSize,
        position: ['bottomCenter'],
        total: usersTotalCount,
        onChange: onPageChange,
      }}
    >
      <Table.Column<UsersListItem>
        title="id"
        key="id"
        dataIndex="id"
      />
      <Table.Column<UsersListItem>
        title="Имя"
        key="name"
        dataIndex="name"
      />
      <Table.Column<UsersListItem>
        title="Дата регистрации"
        key="created"
        dataIndex="created"
        render={(created: string) => (
          dayjs(created).tz('Europe/Moscow').format('DD.MM.YYYY')
        )}
      />
      <Table.Column<UsersListItem>
        title="email"
        key="email"
        dataIndex="email"
      />
      <Table.Column<UsersListItem>
        title="роль"
        key="role"
        dataIndex="role"
      />
      <Table.Column<UsersListItem>
        title="parentId"
        key="parentId"
        dataIndex="parentId"
      />
      <Table.Column<UsersListItem>
        title="телефон"
        key="phone"
        dataIndex="phone"
      />
      <Table.Column<UsersListItem>
        title="telegram"
        key="telegram"
        dataIndex="telegram"
      />
      <Table.Column<UsersListItem>
        title="revshare"
        key="revshareMultiplier"
        dataIndex="revshareMultiplier"
      />
      <Table.Column<UsersListItem>
        title="Валюта"
        key="currency"
        dataIndex="currency"
      />
      <Table.Column<UsersListItem>
        title="Действия"
        key="x"
        render={(_, record) => (
          <ActionsDropdown
            onClick={(key) => onActionsClick(key, record)}
            itemRole={record.role}
          />
        )}
      />
    </Table>
  );
};

export default UsersList;
