import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { userApi } from './userApi';

import type { AccountData } from './types';
import type { RootState } from 'store/store';

export interface UserState {
  account: AccountData;
}

const initialState: UserState = {
  account: {
    id: '',
    created: '',
    name: '',
    email: '',
    role: null,
    currency: '',
    phone: null,
    telegram: null,
    revshareMultiplier: 0,
    parentId: null,
    remoteId: null,
    balance: 0,
  },
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        userApi.endpoints.account.matchFulfilled,
        (state: UserState, action: PayloadAction<AccountData>) => {
          state.account = action.payload;
        },
      );
  },
});

export default slice.reducer;

export const selectAccountData = (state: RootState) => state.user.account;
export const selectUserRole = (state: RootState) => state.user.account.role;
export { initialState as authInitialState };
