import { Button, Form, Input, Select } from 'antd';
import React from 'react';

import { useNotification } from 'hooks/useNotification';
import { useGetCurrenciesQuery } from 'store/app/appApi';
import { useTypedSelector } from 'store/store';
import { useUpdateAccoutMutation } from 'store/user/userApi';
import { selectAccountData } from 'store/user/userSlice';

const { Option } = Select;

export type ChangeUserFormValues = {
  phone: string;
  telegram: string;
  currency: string;
};

type Props = {
  closeModal: () => void;
};

const EditUserForm = ({ closeModal }: Props) => {
  const [form] = Form.useForm();
  const [update, { isError, isSuccess }] = useUpdateAccoutMutation();
  const data = useTypedSelector(selectAccountData);

  const { data: currency } = useGetCurrenciesQuery();

  useNotification({
    isSuccess,
    isError
  });

  const onFinish = async (values: ChangeUserFormValues) => {
    await update(values);
    closeModal();
  };

  return (
    <Form form={form} initialValues={data} onFinish={onFinish}>
      <Form.Item label="Телефон" name="phone">
        <Input />
      </Form.Item>
      <Form.Item label="Телеграм" name="telegram">
        <Input />
      </Form.Item>
      <Form.Item name="currency" label="Валюта" rules={[{ required: true }]}>
        <Select
          placeholder="Валюта"
          allowClear
        >
          {currency?.map((item) => <Option key={item} value={item}>{item}</Option>)}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">Сохранить</Button>
      </Form.Item>
    </Form>
  );
};

export default EditUserForm;
