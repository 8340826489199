import { Button, Table } from 'antd';
import React from 'react';
import dayjs from 'dayjs';
import { AreaChartOutlined } from '@ant-design/icons';

import { Promocode } from 'types/promocodeTypes';

type Props = {
  items?: Promocode[];
  itemsCount?: number;
  loading?: boolean;
  onPageChange?: (page: number, pageSize: number) => void;
  onPromocodeStats?: (promocode: Promocode) => void;
}

const PromocodesTable = (props: Props) => {
  const {
    items,
    itemsCount,
    loading,
    onPageChange,
    onPromocodeStats,
  } = props;

  const tableData = items?.map((item) => ({ ...item, key: item.id }));

  return (
    <Table<Promocode>
      dataSource={tableData}
      loading={loading}
      size='small'
      scroll={{ x: '100%' }}
      style={{ backdropFilter: 'blur(20px)', }}
      pagination={{
        size: 'default',
        showSizeChanger: true,
        pageSizeOptions: [1, 10, 20, 30, 50, 100],
        defaultPageSize: 10,
        position: ['bottomCenter'],
        total: itemsCount,
        onChange: onPageChange,
      }}
    >
      <Table.Column<Promocode>
        title=""
        key="x"
        render={(_, record) => (
          <Button
            size='small'
            icon={<AreaChartOutlined />}
            title="Статистика"
            onClick={() => onPromocodeStats?.(record)}
          />
        )}
      />
      <Table.Column<Promocode>
        title="ID"
        key="id"
        dataIndex="id"
      />
      <Table.Column<Promocode>
        title="Название"
        key="name"
        dataIndex="name"
      />
      <Table.Column<Promocode>
        title="Создан"
        key="created"
        dataIndex="created"
        render={(created: string) => (
          dayjs(created).tz('Europe/Moscow').format('DD.MM.YYYY')
        )}
      />
    </Table>
  );
};

export default PromocodesTable;
