import { Button, Col, DatePicker, Form, Row, Space, Switch } from 'antd';
import React from 'react';
import dayjs from 'dayjs';

interface FormValue {
  createdFrom?: dayjs.Dayjs;
  createdTo?: dayjs.Dayjs;
  ascending?: boolean;
}

export type FilterValues = {
  createdFrom?: string;
  createdTo?: string;
  ascending?: boolean;
}

type Props = {
  onFilter: (filter: FilterValues) => void;
};

const UsersFilter = ({ onFilter }: Props) => {
  const [form] = Form.useForm<FormValue>();

  const onFinish = (values: FormValue) => {
    const createdFrom = values.createdFrom?.tz('Europe/Moscow', true).startOf('day').toISOString();
    const createdTo = values.createdTo?.tz('Europe/Moscow', true).endOf('day').toISOString();

    onFilter({
      createdFrom,
      createdTo,
      ascending: values.ascending
    });
  };

  return (
    <Form<FormValue>
      layout="vertical"
      form={form}
      className="total-stats__filter"
      onFinish={onFinish}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col>
          <Space>
            <Form.Item label="Дата (от)" name="createdFrom" className="filter-form__item">
              <DatePicker format='DD.MM.YYYY' />
            </Form.Item>
            <Form.Item label="Дата (до)" name="createdTo" className="filter-form__item">
              <DatePicker format='DD.MM.YYYY' />
            </Form.Item>
          </Space>
        </Col>
      </Row>
      <Col>
        <Form.Item valuePropName="checked" name="ascending" label="По прибыли">
          <Switch />
        </Form.Item>
      </Col>
      <Col style={{ alignSelf: 'flex-end' }}>
        <Form.Item>
          <Button type="primary" className="filter-form__button" htmlType="submit">Применить</Button>
        </Form.Item>
      </Col>
    </Form>
  );
};

export default UsersFilter;
