import React, { useState } from 'react';
import { Button } from 'antd';

import { useGetClientsQuery } from 'store/clients/clientsApi';
import { useTypedSelector } from 'store/store';
import { selectUserRole } from 'store/user/userSlice';


import ClientsList from './components/ClientsList/ClientsList';
import Modals from './components/Modals';

import type { ClientsItem } from 'store/clients/types';

const ClientsPage = () => {
  const [activeModal, setActiveModal] = useState('');
  const [activeClient, setActiveClient] = useState<ClientsItem | undefined>();
  const role = useTypedSelector(selectUserRole);
  const { data = [], isLoading } = useGetClientsQuery(role || 'admin');

  const handleDropdownClick = (item: ClientsItem, key: string) => {
    setActiveModal(key);
    setActiveClient(item);
  };

  const closeModal = () => {
    setActiveModal('');
    setActiveClient(undefined);
  };

  return (
    <div>
      <Button className="clients-list__add"
        onClick={() => setActiveModal('add-client')}
        hidden={role !== 'admin'}
        type="primary"
      >
        Добавить нового клиента
      </Button>
      <ClientsList
        isLoading={isLoading}
        clients={data}
        onDropdownClick={handleDropdownClick}
      />
      <Modals
        activeModal={activeModal}
        activeClient={activeClient}
        onClose={closeModal}
      />
    </div>
  );
};

export default ClientsPage;
