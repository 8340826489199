import { Layout, Space, Button, Typography, Avatar, Select, Dropdown, MenuProps, Grid, Tooltip, Badge } from 'antd';
import {
  MenuOutlined,
  SettingOutlined, UserOutlined, UserSwitchOutlined, WarningOutlined,
} from '@ant-design/icons';

import { useAppDispatch } from 'store/store';
import { logout } from 'store/auth/authSlice';
import LogoutIcon from 'components/shared/icons/LogoutIcon';
import { useGetCurrenciesQuery } from 'store/app/appApi';
import { useAccountQuery, useUpdateAccoutMutation } from 'store/user/userApi';
import IconCoin from 'components/shared/icons/IconCoin';
import './DashboardHeader.scss';

const { Text } = Typography;
const { useBreakpoint } = Grid;
type Props = {
  onRemoteIdClick?: () => void;
  onSettingsClick: () => void;
  onMenuClick: () => void;
}

const DashboardHeader = ({ onSettingsClick, onMenuClick, onRemoteIdClick }: Props) => {
  const screens = useBreakpoint();
  const dispatch = useAppDispatch();
  const { data: account, isFetching: isAccountLoading } =
    useAccountQuery();
  const { data: currencies = [], isLoading: isCurrenciesLoading } = useGetCurrenciesQuery();
  const [update, { isLoading: isUpdateLoading }] = useUpdateAccoutMutation();

  const handleChangeCurrency = (currency: string) => {
    update({ currency });
  };

  const currencyOptions = currencies.map((currency) => ({
    value: currency,
    label: currency,
  }));

  const shoudLinkRemote = !account?.remoteId && (account?.role === 'manager' || account?.role === 'partner');
  // const shoudLinkRemote = true;
  const isShowBalance = account?.role === 'manager' || account?.role === 'partner';

  const items: MenuProps['items'] = [
    {
      key: 'settings',
      label: 'Settings',
      onClick: onSettingsClick,
      icon: <SettingOutlined />
    },
    {
      key: 'logout',
      label: 'Logout',
      onClick: () => dispatch(logout()),
      icon: <LogoutIcon />
    },
  ];

  if (shoudLinkRemote) {
    items.unshift({
      key: 'remote-id-link',
      danger: true,
      label: 'Связать аккаунт',
      icon: <WarningOutlined />,
      onClick: onRemoteIdClick,
    });
  }

  if (account?.remoteId) {
    items.unshift({
      type: 'divider'
    });
    items.unshift({
      key: 'remote-id',
      label: `id: ${account?.remoteId}`,
      icon: <UserSwitchOutlined />,
    });
  }

  return (
    <Layout.Header className="header" style={{ backdropFilter: 'blur(20px)', }} >
      <Button
        type="text"
        icon={<MenuOutlined />}
        style={{
          fontSize: '16px',
          width: 40,
          height: 40,
          marginRight: 'auto'
        }}
        onClick={onMenuClick}
      />
      <Space>
        {/* {isShowBalance ? (
          <Tooltip title='Баланс' >
            <Space>
              <IconCoin />
              <Typography.Text strong>
                {account?.balance ?? 0}
              </Typography.Text>
            </Space>
          </Tooltip>
        ) : null} */}
        <Select
          value={account?.currency}
          onChange={handleChangeCurrency}
          loading={isCurrenciesLoading || isUpdateLoading || isAccountLoading}
          options={currencyOptions}
          style={{ width: 80 }}
        />
        <Dropdown menu={{ items }} trigger={['click']}>
          <Button type='text' style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Space>
              <Text
                ellipsis
                strong
                style={screens.xs ? { maxWidth: 100 } : undefined}
              >
                {account?.name}
              </Text>
              <Badge dot={shoudLinkRemote}>
                <Avatar size="small" alt={account?.name} icon={<UserOutlined />} />
              </Badge>
            </Space>
          </Button>
        </Dropdown>
      </Space>
    </Layout.Header>
  );
};

export default DashboardHeader;
