import { Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';

import { useNotification } from 'hooks/useNotification';
import { useCreatePartnerLinkMutation } from 'store/partner/partnerApi';
import { PartnerProject } from 'store/partner/types';

type Props = {
  project: PartnerProject | null;
  opened?: boolean;
  onClose?: () => void;
};

const CreateLinkModal = ({ project, opened, onClose }: Props) => {
  const [isCreatedLink, setIsCreatedLink] = useState(false);
  const [createLink, { isError, isSuccess, data: link, isLoading }] = useCreatePartnerLinkMutation();

  useNotification({
    isSuccess,
    isError,
    onSuccess: () => setIsCreatedLink(true),
  });

  const createNewLink = () => {
    if (project) {
      createLink({
        projectId: project.id,
      });
    }
  };

  useEffect(() => {
    setIsCreatedLink(false);
  }, [project?.id]);

  return (
    <Modal
      open={opened}
      title={`Создание новой ссылки в проекте "${project?.name ?? ''}"`}
      onCancel={onClose}
      destroyOnClose
      footer={null}
    >
      {isCreatedLink ? (
        <p>{link}</p>
      ) : (
        <>
          <p style={{ marginBottom: '15px' }}>
            Вы хотите сгенерировать новую ссылку?
          </p>
          <Button type="primary" onClick={createNewLink} loading={isLoading}>
            Сгенерировать
          </Button>
        </>
      )}
    </Modal>
  );
};

export default CreateLinkModal;
