import { Layout, Modal } from 'antd';
import React, { useState } from 'react';

import { useConfigProvider } from 'context/configProvider';

import DashboardSidebar from '../DashboardSidebar/DashboardSidebar';
import DashboardHeader from '../DashboardHeader/DashboardHeader';
import EditUserForm from '../EditUserForm/EditUserForm';
import RemoteIdForm from '../RemoteIdForm/RemoteIdForm';

import type { ItemType } from 'antd/lib/menu/hooks/useItems';

import './DashboardLayout.scss';

type Props = {
  sidebarItems?: ItemType[];
  children?: React.ReactNode;
}

const DashboardLayout = ({ sidebarItems, children }: Props) => {
  const [isCollapsed, setCollapsed] = useState(false);
  const [isEditUser, setEditUser] = useState(false);
  const [isRemoteLink, setRemoteLink] = useState(false);
  const { mode } = useConfigProvider();
  const closeModal = () => {
    setRemoteLink(false);
    setEditUser(false);
  };
  const openSettings = () => setEditUser(true);
  const isLightTheme = mode === 'light';

  return (
    <Layout className="layout">
      <DashboardHeader
        onSettingsClick={openSettings}
        onMenuClick={() => setCollapsed(!isCollapsed)}
        onRemoteIdClick={() => setRemoteLink(true)}
      />
      <Layout>
        <DashboardSidebar
          menuItems={sidebarItems}
          isCollapsed={isCollapsed}
          onCollapsed={(collapsed) => setCollapsed(collapsed)}
        />
        <Layout.Content style={{ margin: '15px 15px 0', overflowX: 'auto' }}>
          <div className="site-layout-background" style={{
            backgroundColor: isLightTheme ? '#ffffff' : 'unset'
          }}
          >
            {children}
          </div>
        </Layout.Content>
      </Layout>
      <Modal
        open={isEditUser}
        title="Измените информацию о своем аккаунте"
        onCancel={closeModal}
        footer={null}
        destroyOnClose
      >
        <EditUserForm closeModal={closeModal} />
      </Modal>

      <Modal
        open={isRemoteLink}
        title="Привязка аккаунта"
        onCancel={closeModal}
        footer={null}
        destroyOnClose
      >
        <RemoteIdForm closeModal={closeModal} />
      </Modal>
    </Layout>
  );
};

export default DashboardLayout;
