import { Button, Col, DatePicker, Form, Input, Row, Switch } from 'antd';
import React from 'react';
import dayjs from 'dayjs';


type FormValue = {
  id?: number;
  name?: string;
  email?: string;
  phone?: string;
  telegram?: string;
  ascending?: boolean;
  transactionFrom?: dayjs.Dayjs;
  transactionTo?: dayjs.Dayjs;
};

export type FilterValues = {
  id?: number;
  name?: string;
  email?: string;
  phone?: string;
  telegram?: string;
  ascending?: boolean;
  transactionFrom?: string;
  transactionTo?: string;
}

type Props = {
  onFilter: (filter: FilterValues) => void;
  onReset?: () => void;
};

const UsersFilter = ({ onFilter, onReset }: Props) => {
  const [form] = Form.useForm<FormValue>();

  const onFinish = (values: FormValue) => {
    onFilter({
      ...values,
      transactionFrom: values.transactionFrom?.tz('Europe/Moscow', true).startOf('day').toISOString(),
      transactionTo: values.transactionTo?.tz('Europe/Moscow', true).endOf('day').toISOString()
    });
  };

  return (
    <Form
      className="filter-form"
      layout="vertical"
      form={form}
      onFinish={onFinish}
      onReset={onReset}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} md={8}>
          <Form.Item label="ID" name="id" className="filter-form__item">
            <Input />
          </Form.Item>
          <Form.Item label="E-mail" name="email" className="filter-form__item">
            <Input />
          </Form.Item>
          <Form.Item label="Телеграм" name="telegram" className="filter-form__item">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item label="Имя" name="name" className="filter-form__item">
            <Input />
          </Form.Item>
          <Form.Item label="Номер телефона" name="phone" className="filter-form__item">
            <Input />
          </Form.Item>
          <Form.Item label="По прибыли" valuePropName="checked" name="ascending">
            <Switch />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Row>
            <Form.Item label="Статистика (от)" name="transactionFrom" className="filter-form__item">
              <DatePicker format='DD.MM.YYYY' />
            </Form.Item>
            <Form.Item label="Статистика (до)" name="transactionTo" className="filter-form__item">
              <DatePicker format='DD.MM.YYYY' />
            </Form.Item>
          </Row>
          <Form.Item label="Управление" className="filter-form__item filter-form__buttons-wrapper">
            <Button type="primary" className="filter-form__button" htmlType="submit">Применить</Button>
            <Button
              className="filter-form__button"
              htmlType='reset'
            >
              Сбросить
            </Button>
          </Form.Item>

        </Col>
      </Row>
    </Form>
  );
};

export default UsersFilter;
