import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import type { RootState } from 'store/store';

const baseQuery = fetchBaseQuery({
  baseUrl: '/api/',
  prepareHeaders: (headers, { getState }) => {
    const { token } = (getState() as RootState).auth;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const api = createApi({
  reducerPath: 'baseApi',
  baseQuery,
  tagTypes: ['Auth', 'User', 'Clients', 'Users', 'Projects', 'Links', 'Stats', 'Promocodes'],
  endpoints: () => ({}),
});
