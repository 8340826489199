import React from 'react';
import { Table } from 'antd';

import { ClientsItem } from 'store/clients/types';

import ActionsDropdown from './ActionsDropdown';
import './ClientsList.scss';

type Props = {
  isLoading: boolean;
  clients: ClientsItem[];
  onDropdownClick: (item: ClientsItem, key: string) => void;
};

const ClientsList = ({ isLoading, clients, onDropdownClick }: Props) => {
  const tableData = clients.map((item) => ({ ...item, key: item.id }));

  return (
    <Table<ClientsItem>
      dataSource={tableData}
      loading={isLoading}
      pagination={false}
      size='small'
      scroll={{ x: 900 }}
    >
      <Table.Column<ClientsItem>
        title="id"
        key="id"
        dataIndex="id"
      />
      <Table.Column<ClientsItem>
        title="domain"
        key="domain"
        dataIndex="domain"
      />
      <Table.Column<ClientsItem>
        title="revshare"
        key="defRevshareMul"
        dataIndex="defRevshareMul"
      />
      <Table.Column<ClientsItem>
        title="ID владельца"
        key="ownerId"
        dataIndex="ownerId"
      />
      <Table.Column<ClientsItem>
        title="Secret"
        key="secret"
        dataIndex="secret"
      />
      <Table.Column<ClientsItem>
        title="Действия"
        key="x"
        render={(_, record) => (
          <ActionsDropdown onClick={(key) => onDropdownClick(record, key)} />
        )}
        width="10%"
      />
    </Table>
  );
};

export default ClientsList;
