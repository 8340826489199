import { Button, Form, Input, Select } from 'antd';
import React from 'react';

import { useNotification } from 'hooks/useNotification';
import { useTypedSelector } from 'store/store';
import { selectUserRole } from 'store/user/userSlice';
import { RolesChangeAdmin, RolesChangeOwner, UsersListItem } from 'store/users/types';
import { useUserUpdateMutation } from 'store/users/usersApi';
import { UserUpdateRequest } from 'types/appType';

type Props = {
  user: UsersListItem | undefined;
  closeModal: () => void;
};

type ErrorRequest = {
  status: number;
  data: {
    detail: string;
  };
};

const UserDetails = ({ user, closeModal }: Props) => {
  const role = useTypedSelector(selectUserRole);
  const [update, { isSuccess, isError, error }] = useUserUpdateMutation();

  const errorMessageNotification = error && error as ErrorRequest;

  const [form] = Form.useForm();

  useNotification({
    isSuccess,
    isError,
    errorMessage: errorMessageNotification
      ? errorMessageNotification.data.detail : ''
  });

  const onFinish = async (value: UsersListItem & { key: number }) => {
    const data: UserUpdateRequest = {
      userId: Number(user?.id),
      name: value.name,
      email: value.email,
      phone: value.phone,
      telegram: value.telegram,
      revshareMultiplier: Number(value.revshareMultiplier),
      role: role || 'admin',
    };

    if (user?.role !== 'owner') {
      if (user?.role !== value.userRole) {
        data.userRole = value.userRole;
      } else {
        data.parentId = Number(value.parentId);
      }
    } else {
      data.userRole = value.userRole;
    }

    const resp = await update(data);
    closeModal();
  };

  return (
    <Form initialValues={user} form={form} onFinish={onFinish}>
      <Form.Item label="Имя" name="name">
        <Input />
      </Form.Item>
      <Form.Item label="emai" name="email">
        <Input />
      </Form.Item>
      <Form.Item label="phone" name="phone">
        <Input />
      </Form.Item>
      <Form.Item label="telegram" name="telegram">
        <Input />
      </Form.Item>
      {user?.role !== 'owner' && (
        <Form.Item label="parentId" name="parentId">
          <Input />
        </Form.Item>
      )}
      {role === 'admin' && (
        <Form.Item label="Роль" name="userRole">
          <Select>
            {RolesChangeAdmin.map((item) => (
              <Select.Option value={item.name} key={item.title}>{item.title}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {role === 'owner' && (
        <Form.Item label="Роль" name="userRole">
          <Select>
            {RolesChangeOwner.map((item) => (
              <Select.Option value={item.name} key={item.title}>{item.title}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item label="revshare" name="revshareMultiplier">
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">Сохранить</Button>
      </Form.Item>
    </Form>
  );
};

export default UserDetails;
