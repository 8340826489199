import React from 'react';
import { Button, Col, DatePicker, Drawer, Form, Row, Space, Typography } from 'antd';
import dayjs from 'dayjs';

import { StatsInfo } from 'types/statsApi';
import StatsTotal from 'components/dashboard/components/StatsTotal/StatsTotal';
import { createStatsListShort } from 'utils/stats';

import StatsUsersTable from '../StatsUsersTable/StatsUsersTable';

interface FormValue {
  createdFrom?: dayjs.Dayjs;
  createdTo?: dayjs.Dayjs;
  ascending?: boolean;
}

export type FilterValues = {
  createdFrom?: string;
  createdTo?: string;
  ascending?: boolean;
}

type Props = {
  renderDescription?: () => React.ReactNode;
  opened?: boolean;
  loading?: boolean;
  onClose?: () => void;
  onFilter?: (filter: FilterValues) => void;
  onFilterReset?: () => void;
  onPageChange?: (page: number, pageSize: number) => void;
  stats?: StatsInfo;
  title?: React.ReactNode;
}

const ShortStatsModal = (props: Props) => {
  const {
    renderDescription,
    opened,
    onClose,
    onFilter,
    onFilterReset,
    onPageChange,
    loading,
    stats,
    title,
  } = props;

  const [form] = Form.useForm<FormValue>();
  const onFinish = (values: FormValue) => {
    const createdFrom = values.createdFrom?.tz('Europe/Moscow', true)
      .startOf('day').toISOString();
    const createdTo = values.createdTo?.tz('Europe/Moscow', true)
      .endOf('day').toISOString();

    onFilter?.({
      createdFrom: createdFrom,
      createdTo: createdTo,
    });
  };

  const handleClose = () => {
    onClose?.();
    form.resetFields();
  };

  const statsList = createStatsListShort(stats);
  const playerStats = stats?.playerStats;

  return (
    <Drawer
      title={title}
      width={1200}
      onClose={handleClose}
      open={opened}
      destroyOnClose
      styles={{
        body: {
          paddingBottom: 80,
        },
        content: {
          backdropFilter: 'blur(20px)',
        }
      }}
    >
      {renderDescription?.()}
      <Form<FormValue>
        layout="vertical"
        form={form}
        className="total-stats__filter"
        onFinish={onFinish}
        onReset={onFilterReset}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col>
            <Space.Compact>
              <Form.Item label="Дата (от)" name="createdFrom" className="filter-form__item">
                <DatePicker format='DD.MM.YYYY' />
              </Form.Item>
              <Form.Item label="Дата (до)" name="createdTo" className="filter-form__item">
                <DatePicker format='DD.MM.YYYY' />
              </Form.Item>
            </Space.Compact>
          </Col>
          <Col style={{ alignSelf: 'flex-end' }}>
            <Form.Item>
              <Space.Compact>
                <Button type="primary" className="filter-form__button" htmlType="submit" loading={loading}>
                  Применить
                </Button>
                <Button className="filter-form__button" htmlType="reset">
                  Сбросить
                </Button>
              </Space.Compact>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <StatsTotal
        title='Общая статистика'
        statsList={statsList}
        isLoading={loading}
      />

      {(playerStats && playerStats.length > 0) ? (
        <>
          <Typography.Title level={4}>
            Пользователи
          </Typography.Title>
          <StatsUsersTable
            playerStats={playerStats}
            playerStatsCount={stats?.playerStatsCount}
            defaultPageSize={10}
            loading={loading}
            onPageChange={onPageChange}
          />
        </>
      ) : null}
    </Drawer>
  );
};

export default ShortStatsModal;
