import Icon from '@ant-design/icons';

import { ReactComponent as MoonSVG } from 'assets/icons/icon-moon.svg';

import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const IconMoon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={MoonSVG} {...props} />
);

export default IconMoon;
