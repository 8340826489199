import Icon from '@ant-design/icons';

import { ReactComponent as SunSvg } from 'assets/icons/icon-sun.svg';

import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const IconSun = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SunSvg} {...props} />
);

export default IconSun;
