import { PlayerStats, PlayerStatsResponse, StatsInfo, StatsInfoResponse } from 'types/statsApi';
import { PartnerLink, PartnerLinkServer, PartnerLinks, PartnerLinksServer } from 'store/partner/types';

import type { AccountData, AccountDataResponse, Withdrawals, WithdrawalsResponse } from 'store/user/types';


export const Adapter = {
  accauntData: (res: AccountDataResponse): AccountData => ({
    id: res.id.toString(),
    created: res.created,
    name: res.name,
    email: res.email,
    role: res.role,
    currency: res.currency,
    phone: res.phone,
    telegram: res.telegram,
    revshareMultiplier: res.revshare_multiplier,
    parentId: res.parent_id,
    remoteId: res.remote_id,
    balance: res.balance,
  }),

  playerStatsToClient: (res: PlayerStatsResponse): PlayerStats => ({
    playerId: res.player_id,
    playerName: res.player_name,
    deposits: res.deposits,
    adminDeposits: res.admin_deposits,
    betsPlaced: res.bets_placed,
    betsWinnings: res.bets_winnings,
    betsLosses: res.bets_losses,
    betsIngame: res.bets_ingame,
    betsIncome: res.bets_income,
    inbetIncome: res.inbet_income,
    inbetPlaced: res.inbet_placed,
    inbetWinnings: res.inbet_winnings,
    betgamesIncome: res.betgames_income,
    betgamesPlaced: res.betgames_placed,
    betgamesWinnings: res.betgames_winnings,
    vegasPlaced: res.vegas_placed,
    vegasWinnings: res.vegas_winnings,
    vegasIncome: res.vegas_income,
    income: res.income,
  }),

  statsInfoToClient: (res: StatsInfoResponse): StatsInfo => ({
    numClicks: res.num_clicks ?? 0,
    numRegistrations: res.num_registrations ?? 0,

    deposits: res.deposits ?? 0,
    adminDeposits: res.admin_deposits ?? 0,
    firstDeposits: res.first_deposits ?? 0,
    betsPlaced: res.bets_placed ?? 0,
    betsWinnings: res.bets_winnings ?? 0,
    betsLosses: res.bets_losses ?? 0,
    betsIngame: res.bets_ingame ?? 0,
    betsIncome: res.bets_income ?? 0,

    inbetIncome: res.inbet_income ?? 0,
    inbetPlaced: res.inbet_placed ?? 0,
    inbetWinnings: res.inbet_winnings ?? 0,
    betgamesIncome: res.betgames_income ?? 0,
    betgamesPlaced: res.betgames_placed ?? 0,
    betgamesWinnings: res.betgames_winnings ?? 0,
    vegasPlaced: res.vegas_placed ?? 0,
    vegasWinnings: res.vegas_winnings ?? 0,
    vegasIncome: res.vegas_income ?? 0,

    income: res.income ?? 0,
    revshare: res.revshare ?? 0,
    playerStatsCount: res.player_stats_count ?? 0,
    playerStats: res.player_stats.map(Adapter.playerStatsToClient) ?? [],
  }),

  withdrawalsToClient: (res: WithdrawalsResponse): Withdrawals => ({
    count: res.count,
    items: res.items.map((item) => ({
      id: item.id,
      created: item.created,
      userId: item.user_id,
      amount: item.amount,
      currency: item.currency,
      status: item.status,
    }))
  }),

  partnerLink: (res: PartnerLinkServer): PartnerLink => ({
    refCode: res.ref_code,
    created: res.created,
    projectId: res.project_id,
    projectName: res.project_name,
    link: res.link,
    title: res.title,
  }),

  partnerLinks: (res: PartnerLinksServer): PartnerLinks => ({
    count: res.count,
    items: res.items.map(Adapter.partnerLink)
  })
};
