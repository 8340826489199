import React, { useState } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { useGetPartnerProjectsQuery, useCreatePartnerProjectMutation } from 'store/partner/partnerApi';
import { defaultPageSize } from 'utils/constants';
import { useNotification } from 'hooks/useNotification';
import { GetPartnerProjects, PartnerProject } from 'store/partner/types';

import ProjectsTable from './components/ProjectsTable';
import CreateProjectModal from './components/CreateProjectModal';
import CreateLinkModal from './components/CreateLinkModal';
import ProjectStatsModal from './components/ProjectStatsModal';


const initParams = {
  pageSize: defaultPageSize,
  page: 1,
};

type Modals = 'create-project' | 'create-link' | 'project-stats';

const ProjectPage = () => {
  const [activeModal, setActiveModal] = useState<Modals | null>(null);
  const [selectedProject, setSelectedProject] = useState<PartnerProject | null>(null);
  const [params, setParams] = useState<GetPartnerProjects>(initParams);
  const { data, isFetching } = useGetPartnerProjectsQuery(params);

  const [createProject, {
    isError: isCreateProjectError,
    isSuccess: isCreateProjectSuccess,
    isLoading: isCreateProjectLoading,
  }] = useCreatePartnerProjectMutation();

  useNotification({
    isSuccess: isCreateProjectSuccess,
    isError: isCreateProjectError,
    onSuccess: () => closeModal(),
  });

  const closeModal = () => {
    setActiveModal(null);
    setSelectedProject(null);
  };

  const handleCreateProject = (projectName: string) => {
    createProject(projectName);
  };

  const handlePageChange = (page: number, pageSize: number) =>
    setParams((prevParams) => ({ ...prevParams, page, pageSize }));

  const handleCreateLinkClick = (project: PartnerProject) => {
    setSelectedProject(project);
    setActiveModal('create-link');
  };

  const handleOpenStats = (project: PartnerProject) => {
    setSelectedProject(project);
    setActiveModal('project-stats');
  };

  return (
    <div>
      <Button
        type="primary"
        style={{ marginBottom: '15px' }}
        onClick={() => setActiveModal('create-project')}
        icon={<PlusOutlined />}
      >
        Новый проект
      </Button>
      <ProjectsTable
        items={data?.items ?? []}
        isLoading={isFetching}
        onPageChange={handlePageChange}
        defaultPageSize={defaultPageSize}
        itemsCount={data?.count ?? 0}
        onCreateLink={handleCreateLinkClick}
        onProjectStat={handleOpenStats}
      />
      <CreateProjectModal
        opened={activeModal === 'create-project'}
        onClose={closeModal}
        onCreate={handleCreateProject}
        loading={isCreateProjectLoading}
      />
      <CreateLinkModal
        project={selectedProject}
        opened={activeModal === 'create-link' && !!selectedProject}
        onClose={closeModal}
      />
      <ProjectStatsModal
        opened={activeModal === 'project-stats'}
        onClose={closeModal}
        project={selectedProject}
      />
    </div >
  );
};

export default ProjectPage;
