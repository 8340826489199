import { api } from 'store/api';
import { ToSnakeCase } from 'types/transformTypes';
import { camelToSnakeCase, snakeToCamelCase } from 'utils/transforms';
import { Adapter } from 'utils/adapter';

import type { StatsInfo } from 'types/statsApi';
import type { BackendError } from 'types/errorTypes';
import type {
  GetPromocodes,
  Promocodes,
  PromocodeScript,
  GetPromocodesStatsParams,
  CreatePromocodesParams,
} from 'types/promocodeTypes';
import type {
  ClientsItem,
  ClientsListServer,
  newClientsBody,
  newClientsItem
} from './types';

type StatsClientParams = {
  clientId: number;
  createdFrom?: string;
  createdTo?: string;
  partnersOnly?: boolean;
};

const clientsAPi = api.injectEndpoints({
  endpoints: (builder) => ({

    getClients: builder.query<ClientsItem[], string>({
      query: (role: string) => ({
        url: `v1/${role}/client${role === 'admin' ? 's' : ''}`,
        method: 'GET'
      }),
      providesTags: ['Clients'],
      transformResponse(response: ClientsListServer): ClientsItem[] {
        return response.map((item) => snakeToCamelCase(item));
      }
    }),

    updateClient: builder.mutation<void, newClientsItem>({
      query: (data: newClientsItem) => ({
        url: `v1/${data.role}/client${data.role === 'admin' ? `s/${data.id || ''}` : ''}`,
        method: 'PUT',
        body: camelToSnakeCase<newClientsBody, ToSnakeCase<newClientsBody>>({ ...data.body })
      }),
      invalidatesTags: ['Clients']
    }),

    addClients: builder.mutation<void, string>({
      query: (domain: string) => ({
        url: 'v1/admin/clients',
        method: 'POST',
        body: {
          domain
        }
      }),
      invalidatesTags: ['Clients']
    }),

    statsClient: builder.query<StatsInfo, StatsClientParams>({
      query: (params) => ({
        url: `v1/admin/stats/client/${params.clientId}`,
        method: 'GET',
        params: {
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
          'partners_only': params.partnersOnly,
        }
      }),
      providesTags: ['Stats'],
      transformResponse: Adapter.statsInfoToClient,
    }),

    getOwnerPromocodeScripts: builder.query<PromocodeScript[], void>({
      query: () => ({
        url: 'v1/owner/promocode/scripts',
      }),
    }),

    getOwnerPromocodes: builder.query<Promocodes, GetPromocodes>({
      query: (params) => ({
        url: 'v1/owner/promocodes',
        params: {
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
          'order_by': params.orderBy,
        }
      }),
      providesTags: ['Promocodes'],
    }),

    createOwnerPromocodes: builder.mutation<void, CreatePromocodesParams>({
      query: (params) => ({
        url: 'v1/owner/promocodes',
        method: 'POST',
        params: {
          'name': params.name,
          'script_id': params.scriptId,
        }
      }),
      transformErrorResponse: (
        baseQueryReturnValue: {
          status: number;
          data?: BackendError;
        }
      ): string => {
        if (!baseQueryReturnValue.data || !baseQueryReturnValue.data.detail) {
          return 'Ошибка при создании промокода';
        }
        const { detail } = baseQueryReturnValue.data;
        if (typeof detail === 'string') {
          switch (detail) {
            case 'Promocode already exists':
              return 'Такой промокод уже существует';
            default:
              return detail;
          }
        }
        return 'Ошибка при создании промокода';
      },
      invalidatesTags: ['Promocodes'],
    }),

    getOwnerPromocodeStats: builder.query<StatsInfo, GetPromocodesStatsParams>({
      query: (params) => ({
        url: `v1/owner/stats/promocode/${params.promocodeId}`,
        params: {
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
          'order_by': params?.orderBy,
        }
      }),
      transformResponse: Adapter.statsInfoToClient,
    }),

  })
});

export const {
  useGetClientsQuery,
  useUpdateClientMutation,
  useAddClientsMutation,
  useStatsClientQuery,
  useGetOwnerPromocodeScriptsQuery,
  useGetOwnerPromocodesQuery,
  useCreateOwnerPromocodesMutation,
  useGetOwnerPromocodeStatsQuery,
} = clientsAPi;
