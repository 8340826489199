import { Button, Modal } from 'antd';

import UserDetails from './UserDetails';
import UserLinks from './UserLinks';
import PartnerUsers from './PartnerUsers';
import UserPromocodes from './UserPromocodes';

import type { UsersListItem } from 'store/users/types';

type Props = {
  activeModal: string;
  onClose: () => void;
  selectedUser?: UsersListItem;
}

const Modals = ({ activeModal, onClose, selectedUser }: Props) => (
  <>
    <Modal
      open={activeModal === 'details'}
      title={`Информация о пользователе ${selectedUser?.name ?? ''}`}
      onCancel={onClose}
      destroyOnClose
      footer={null}
    >
      <UserDetails user={selectedUser} closeModal={onClose} />
    </Modal>

    <Modal
      open={activeModal === 'link-user'}
      title={`Созданные ссылки ${selectedUser?.name ?? ''}`}
      onCancel={onClose}
      destroyOnClose
      width="85%"
      styles={{ body: { overflowX: 'auto' } }}
      footer={null}
      style={{ backdropFilter: 'blur(20px)', }}
    >
      <UserLinks id={selectedUser?.id || 0} />
    </Modal>
    <Modal
      open={activeModal === 'promocode-user'}
      title={`Созданные промокоды ${selectedUser?.name ?? ''}`}
      onCancel={onClose}
      destroyOnClose
      width="85%"
      styles={{ body: { overflowX: 'auto' } }}
      footer={null}
      style={{ backdropFilter: 'blur(20px)', }}
    >
      <UserPromocodes id={selectedUser?.id || 0} />
    </Modal>
    <Modal
      open={activeModal === 'partner-users-list'}
      title={`Список пользователей партнёра: ${selectedUser?.id ?? ''}/${selectedUser?.name ?? ''}`}
      onCancel={onClose}
      destroyOnClose
      width="85%"
      styles={{ body: { overflowX: 'auto' } }}
      footer={null}
    >
      {selectedUser?.id && <PartnerUsers id={selectedUser?.id} />}
    </Modal>
  </>
);

export default Modals;
