import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import DashboardAdmin from 'components/dashboard/DashboardAdmin/DashboardAdmin';
import ProtectedRoute from 'components/shared/ProtectedRoute/ProtectedRoute';
import DashboardOwner from 'components/dashboard/DashboardOwner/DashboardOwner';
import DashboardManager from 'components/dashboard/DashboardManager/DashboardManager';
import DashboarPartner from 'components/dashboard/DashboardPartner/DashboarPartner';
import LoginPage from 'pages/LoginPage/LoginPage';
import RegistrationPage from 'pages/RegistrationPage/RegistrationPage';
import { ConfigProvider } from 'context/configProvider';

import { useAccountQuery } from '../store/user/userApi';
import { useTypedSelector } from '../store/store';
import { selectIsAuthenticated } from '../store/auth/authSlice';
import { selectUserRole } from '../store/user/userSlice';

import './App.scss';

const App = () => {
  const isAuth = useTypedSelector(selectIsAuthenticated);
  const role = useTypedSelector(selectUserRole);
  const { isLoading: isAccountLoading } = useAccountQuery();

  return (
    <div className="app">
      <ConfigProvider>
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          <Route path="login" element={<LoginPage isAuth={isAuth} />} />
          <Route path="ref_code=:id" element={<RegistrationPage isAuth={isAuth} />} />
          <Route
            path="/dashboard/*"
            element={(
              <ProtectedRoute isLoading={isAccountLoading} isAuth={isAuth}>
                <>
                  {role === 'admin' && <DashboardAdmin />}
                  {role === 'owner' && <DashboardOwner />}
                  {role === 'manager' && <DashboardManager />}
                  {role === 'partner' && <DashboarPartner />}
                </>
              </ProtectedRoute>
            )}
          />
          <Route path="*" element={<h1>Not founded</h1>} />
        </Routes>
      </ConfigProvider>
    </div>
  );
};

export default App;
