import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Spin } from 'antd';

import './ProtectedRoute.scss';

export type ProtectedRouteProps = {
  isAuth: boolean;
  isLoading: boolean;
  children: JSX.Element;
};

const ProtectedRoute = ({ isAuth, children, isLoading }: ProtectedRouteProps) => {
  const location = useLocation();
  if (isLoading) {
    return (
      <div className="protected-route">
        <Spin />
      </div>
    );
  }
  return isAuth ? children : <Navigate to="/login" state={{ from: location }} />;
};

export default ProtectedRoute;
